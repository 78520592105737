

<div *ngIf="servererror" class="alert alert-warning alert-dismissible fade show" role="alert">
  Une erreur de connexion au serveur est apparue. Veuillez réessayer
  plus tard ou contacter l'administrateur (<a href="mailto: inscription@gphg.org">inscription@gphg.org</a>) /
  Please retry later or contact the administrator (<a href="mailto: inscription@gphg.org">inscription@gphg.org</a>).
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>

<div class="row mb-2">
  <div class="col-3">Prénom / First Name:</div>
  <div class="col-9">{{ registrationmodel?.firstname }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Nom / Last Name:</div>
  <div class="col-9">{{ registrationmodel?.name }}</div>
</div>
<div class="row" mb-2>
  <div class="col-3">Fonction / Job Title:</div>
  <div class="col-9">{{ registrationmodel?.function }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Société / Company name:</div>
  <div class="col-9">{{ registrationmodel?.company }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Email:</div>
  <div class="col-9">{{ registrationmodel?.email }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Téléphone / Phone:</div>
  <div class="col-9">{{ registrationmodel?.phone }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Activités / Activities:</div>
  <div class="col-9">{{ registrationmodel?.event?.label }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Date:</div>
  <div class="col-9">{{ formatDate2(registrationmodel?.eventselecteddate2, 'DD/MM/YYYY hh:mm:ss', 'DD/MM/YYYY') }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Heure / Starting time:</div>
  <div class="col-9">{{ formatTime(registrationmodel?.eventselectedtime)  }}</div>
</div>
<div class="row mb-2">
  <div class="col-3">Nombre de personnes / Persons:</div>
  <div class="col-9">{{ registrationmodel?.personcount }}</div>
</div>
