import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Params} from '../shared/params';
import {Observable, of} from 'rxjs';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class EventTimeService {

  private serverRoot: string;

  constructor(
    private http: HttpClient,
    private params: Params
  ) {
    this.serverRoot = this.params.getBffapiroot();
  }

  getTimesOfEventAndDate(date: NgbDateStruct, event: string): Observable<any> {
    if (!date || !event) {
      return of(null);
    } // if

    // tslint:disable-next-line:max-line-length
    const url = '/v1/event-times?activity=' + event + '&day=' + date.day.toString() + '&month=' + date.month.toString() + '&year=' + date.year.toString();
    return this.http.get(this.serverRoot + url);
  }

  getAvailableSeats(date: NgbDateStruct, time: NgbTimeStruct, event: string): Observable<any> {
    if (!date || !event || !time) {
      return of(null);
    } // if

    const params = [
      'activity=' + event,
      'day=' + date.day.toString(),
      'month=' + date.month.toString(),
      'year=' + date.year.toString(),
      'hour=' + time.hour.toString(),
      'minute=' + time.minute.toString(),
      'second=' + time.second.toString()
    ];
    const url = '/v1/event-seats?' + params.join('&');
    return this.http.get(this.serverRoot + url);
  }

}
