import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {GphgPageComponent} from './views/gphg-page/gphg-page.component';
import {RegistrationFormComponent} from './views/registration-form/registration-form.component';
import {RegistrationFormSucessComponent} from './views/registration-form-sucess/registration-form-sucess.component';
import {MainAdminComponent} from './views/admin/main-admin/main-admin.component';
import {RegistrationAdminComponent} from './views/admin/registration-admin/registration-admin.component';
import {DashboardAdminComponent} from './views/admin/dashboard-admin/dashboard-admin.component';
import {LoginAdminComponent} from './views/admin/login-admin/login-admin.component';
import {RouteGuard} from './security/route.guard';
import {EventRegistrationFormComponent} from '@views/event-registration-form/event-registration-form.component';
import {AtelierMainComponent} from '@views/admin/mediation/atelier/atelier-main/atelier-main.component';
import {AtelierAddComponent} from '@views/admin/mediation/atelier/atelier-add/atelier-add.component';
import {EventMainComponent} from '@views/admin/mediation/event/event-main/event-main.component';
import {EventAddComponent} from '@views/admin/mediation/event/event-add/event-add.component';
import {
  RegistrationAddComponent
} from '@views/admin/mediation/registration/registration-add/registration-add.component';
import {
  RegistrationMainComponent
} from '@views/admin/mediation/registration/registration-main/registration-main.component';



const routes: Routes = [
  {
    path: 'login',
    component: LoginAdminComponent,
  },
  {
    path: 'admin',
    component: MainAdminComponent,
    canActivate: [RouteGuard],
    children: [
      {
        path: '',
        component: DashboardAdminComponent,
      },
      {
        path: 'atelier',
        children: [
          {
            path: '',  // List of all ateliers,
            component: AtelierMainComponent
          },
          {
            path: 'add',
            component: AtelierAddComponent
          },
          {
            path: ':atelierid/edit',
            component: AtelierAddComponent
          },
          // {
          //   path: 'viewatelier/:atelierid'
          // }
        ]
      },
      {
        path: ':eventkey/events',
        children: [
          {
            path: '',  // List of all event
            component: EventMainComponent,
          },
          {
            path: 'add',
            component: EventAddComponent
          },
          {
            path: ':eventid/edit',
            component: EventAddComponent
          },
          // {
          //   path: 'viewevent/:eventid'
          // }
        ]
      },
      {
        path: 'registration',
        children: [
          {
            path: '',  // List of all registration
            component: RegistrationMainComponent
          },
          {
            path: 'add',
            component: RegistrationAddComponent
          },
          {
            path: ':registrationid/editregistration',
            component: RegistrationAddComponent
          },
          // {
          //   path: 'viewregistration/:registrationid'
          // }
        ]
      },
      {
        path: ':eventkey/registrations',
        component: RegistrationAdminComponent,
        runGuardsAndResolvers: 'always',
      }
    ]
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '2020-|-|',
        component: GphgPageComponent,
        children: [
          {
            path: 'inscription-ceremonie',
            component: RegistrationFormComponent,
          },
          {
            path: 'groupe/inscription-ceremonie',
            component: RegistrationFormComponent,
          },
          {
            path: 'inscription-ceremonie/succes',
            component: RegistrationFormSucessComponent,
          },
          {
            path: 'groupe/inscription-ceremonie/succes',
            component: RegistrationFormSucessComponent,
          }
        ]
      },
      {
        // path: ':gphgyear',
        path: 'yUd9gjYq4t1lRu2GzDMxpaITAYvrm3urqXqTxForqf6K9rpumw2mo03I7SlcCVzq',
        children: [
          {
            path: 'inscription-evenements',
            component: EventRegistrationFormComponent,
          }
        ]
      },
      {
        // path: ':gphgyear',
        path: 'oSMSH2MV2dHhj3RI',
        component: GphgPageComponent,
        children: [
          {
            path: 'inscription-ceremonie',
            component: RegistrationFormComponent,
          },
          {
            path: 'groupe2/inscription-ceremonie',
            component: RegistrationFormComponent,
          },
          {
            path: 'groupe5/inscription-ceremonie',
            component: RegistrationFormComponent,
          },
          {
            path: 'groupe10/inscription-ceremonie',
            component: RegistrationFormComponent,
          },
        ]
      },
      {
        path: 'inscription-ceremonie/succes',
        component: RegistrationFormSucessComponent,
      },
      {
        path: 'groupe/inscription-ceremonie/succes',
        component: RegistrationFormSucessComponent,
      }
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
