import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import {AvailableSeatsService} from '../../services/available-seats.service';
import {EventService} from '../../services/event.service';
import {RegistrationService} from '../../services/registration.service';
import {EventDateService} from '../../services/event-date.service';
import {DateService} from '../../shared/date.service';
import {EventTimeService} from '../../services/event-time.service';
import {FormDynamicsService} from '../../services/form-dynamics.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Params} from '../../shared/params';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-event-registration-form',
  templateUrl: './event-registration-form.component.html',
  styleUrls: ['./event-registration-form.component.scss']
})
export class EventRegistrationFormComponent implements OnInit {

  form: FormGroup;
  fields: FormlyFieldConfig[] = [];
  model: any = {};
  formstep: string = '';

  eventDetailTitle: string = "";
  eventDetailDescription: string = "";
  availableseats: number|null = null;

  successmessage: string = '';
  errormessage: string = '';

  servererror: boolean = false;

  enabledDates: Date[] = [];
  isPersonCountHidden: boolean = true;

  options: FormlyFormOptions;

  showDebug: boolean = false;

  yeartag: string = '';

  submitInProgress: boolean = false;

  constructor(
    private ref: ChangeDetectorRef,
    private calendar: NgbCalendar,
    private availableSeatService: AvailableSeatsService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private eventDateService: EventDateService,
    private eventTimeService: EventTimeService,
    private dateService: DateService,
    private formDynamicsService: FormDynamicsService,
    private sanizizer: DomSanitizer,
    private appParameters: Params,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit(): void {

    this.showDebug = this.appParameters.getDebug();

    this.yeartag = this.activatedRoute.snapshot.params.gphgyear;

    this.form = new FormGroup<any>({});

    this.eventDetailTitle = "test1";
    this.eventDetailDescription = "test1";
    this.formstep = "";

    this.options = {
      formState: {
        isPersonCountHiddenAsync: false,
        availableseats: null
      },
    };

    this.fields = [
      {
        key: 'firstname',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Prénom / First Name',
          placeholder: '',
          required: true,
        }
      },
      {
        key: 'name',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Nom / Last Name',
          placeholder: '',
          required: true,
        }
      },
      {
        key: 'function',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Fonction / Job Title',
        },
      },

      {
        key: 'company',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Société / Company name',
        },
      },
      {
        key: 'email',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Email',
          placeholder: '',
          required: true,
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) => `e-mail non valide / Not valid e-mail`,
          },
        },
      },
      {
        key: 'phone',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Téléphone / Phone',
          placeholder: '',
          required: true,
          pattern: /^[0-9+\- ]{1,20}$/,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) =>
              `Seuls les caractères numériques sont acceptés / Only numeric values are accepted`,
          },
        },
      },
      {
        template: `<hr /><h5>Choisir votre activité / Please choose your activity</h5>`
      },
      {
        key: 'event',
        type: 'select',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Activités / Activities',
          change: this.onChangeEvent,
          required: true,
          valueProp: (option) => option,
          compareWith: this.eventCompareWith,
          // options: [],
          options: this.formDynamicsService.getEventOptions$()
        },
        hooks: {
          onInit: this.onInitEvent,
        }
      },
      {
        key: 'eventdetail',
        template: this.setEventDetail()
      },
      {
        key: 'eventselecteddate2',
        type: 'date-picker-ngx',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Date',
          required: true,
          placeholder: "dd/mm/yyyy",
          bsConfig: {
            dateInputFormat: 'DD/MM/YYYY',
            containerClass: 'theme-red'
          },
          customDatesEnabled: this.formDynamicsService.getSelectedDateEnabled$(),
          onShown: this.onShowDatePicker,
          onHidden: this.onHiddenDatePicker,
          minDate: this.getMinDate(),
          maxDate: this.getMaxDate(),
          // disabled: true,
          customBsValueChange: this.onChangeDateNgx
        },
        hooks: {
          onInit: this.onInitSelectedDateNgx,
        },
      },
      {
        key: 'eventselectedtime',
        type: 'select',
        wrappers: ['form-field-horizontal'],
        // defaultValue: this.selectedTimeDefaultValue(),
        props: {
          required: true,
          label: 'Heure / Starting time',
          change: this.onChangeSelectedTime,
          options: this.formDynamicsService.getEventTimeOptions$(),
          // valueProp: (option) => option,
          compareWith(currentValue: any, compareValue: any): boolean {
            // console.log('currentValue');
            // console.log(currentValue);
            // console.log('compareValue');
            // console.log(compareValue);
            // return _.isEqual(currentValue.value, compareValue);
            return _.isEqual(currentValue, compareValue);
          },
        },
        hooks: {
          onInit: this.onInitSelectedTime,
        },
        expressions: {
          hide: this.hideEventSelectedTimeLogic
        }
      },
      {
        key: 'registeredpersons',
        type: 'custom-template',
        props: {
          htmlAsync: this.formDynamicsService.getSeatTemplate$(),
        },
        hooks: {
          onInit: this.onInitRegisteredPersons
        }
      },
      {
        key: 'personcount',
        type: 'number',
        // defaultValue: 0,
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Nombre de personnes / Number of participants',
          placeholder: '',
          required: true,
          min: 0,
          change: this.onChangePersonCount
        },
        validators: {
          validation: ['notzero'],
          personcountvalid: {
            expression: (control: AbstractControl) => {

              // if (this.options.formState.availableseats === 0
              //   || this.options.formState.availableseats === null) {
              //   return true;
              // } // if

              return control.value <= this.options.formState.availableseats; // if
            },
            message: 'Places insuffisantes / Not enough seats available.',
          },
        },
        expressions: {
          hide: 'formState.isPersonCountHiddenAsync'
        }
      },
      {
        template: '<hr />'
      }
    ];

    this.formDynamicsService.setSelectedDateEnabled([new Date('xxx')]);



    // this.model = {
    //   firstname: "Breanna",
    //   name: "Perry",
    //   email: "ranaivo.razakanirina@atety.com",
    //   phone: "05 98 56 56 66",
    //   event: {
    //     label: "ATELIERS HORLOGERS / WATCHMAKING WORKSHOPS (10 seats)",
    //     value: "atelier_horloger"
    //   },
    //   // personcount: 2,
    //   //
    //   // eventselectedtime: {
    //   //   hour: 11,
    //   //   minute: 0,
    //   //   second: 0
    //   // },
    //   // eventselecteddate2: "8/11/2023 00:00:00"
    // };

  }

  getMinDate(): Date {
    // Init the min and max date
    switch (this.yeartag) {
      case 'gphg2023':
        return new Date('2023-10-26');
      case 'gphg2024':
        return new Date('2024-10-23');
      default:
        return new Date();
    } // switch

  }

  getMaxDate(): Date {

    switch (this.yeartag) {
      case 'gphg2023':
        return new Date('2023-11-12');
      case 'gphg2024':
        return new Date('2024-11-17');
      default:
        return new Date();
    } // switch
  }

  onChangeEvent = (field: FormlyFieldConfig, event: any): void => {
    // console.log('*** onChangeSelectedEvent');
    // console.log('new activity = ');
    // console.log(this.model.event);

    //
    // Reset value for model
    //
    this.form.get('eventselecteddate2')?.setValue(null);
    delete this.model?.eventselecteddate2;


    this.form.get('eventselectedtime')?.setValue('');
    delete this.model?.eventselectedtime;
    this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );


    this.formDynamicsService.setSeatTemplate( this.getAvailableSeatTemplate(null) );


    this.form.get('personcount')?.setValue('');
    delete this.model?.personcount;
    this.options.formState.isPersonCountHiddenAsync = false;
    this.options.formState.availableseats = null;


    const selectedEventValue = this.model?.event?.value;
    if (!selectedEventValue) {
      return;
    } // if

    const dates$ = this.eventDateService.getDatesForEvent(selectedEventValue);
    dates$.subscribe({
      next: results => {

        // console.log('update date enabled async');

        const eventDatesNgbDate = results.results;
        let eventDatesMomentLocalTimezone = [];
        if ( !_.isArray(eventDatesNgbDate) || eventDatesNgbDate.length === 0 ) {
          this.formDynamicsService.setSelectedDateEnabled([new Date('xxx')]);
        } else {
          // convertedDates = this.dateService.convertArrayOfDateStringsToArrayOfMoment(eventDatesNgbDate);

          eventDatesMomentLocalTimezone = this.dateService
            .convertArrayOfNgbDateStructToArrayOfMomentLocalTimezone(eventDatesNgbDate);



          this.formDynamicsService.setSelectedDateEnabled(eventDatesMomentLocalTimezone);
        } // if
      }
    });

  }

  onInitEvent = (field: FormlyFieldConfig) => {
    // console.log('*** onInitEvent');

    // Fill the options
    const events$ = this.eventService.getActivities(this.yeartag);
    events$.subscribe({
      next: results => {
        this.formDynamicsService.setEventOptions(results?.results);
      }
    });

  }


  getDefaultSelectOption(): any[] {
    // return [{label: "---" }, {label: "15.00", value: {hour: 15, minute: 0, second: 0}}];
    return [{label: "---"}];
  }

  onChangeDateNgx = (value) => {

    // console.log('*** onChangeDateNgx');

    // If value or model value are undefined, do not apply on change.
    if (!value) {
      return;
    } // if

    //
    // Verify if it is a change or the same value
    // !!! This condition is important to avoid launching onChange during the initialization of the process.
    //
    // console.log('new value = ' + value);
    // console.log('old value = ' + this.model?.eventselecteddate2);

    const newMomentSelectedDate2 = this.dateService
      .convertDateStringToMoment(value, moment.ISO_8601);
    const oldMomentSelectedDate2 = this.dateService
      .convertDateStringToMoment(this.model?.eventselecteddate2, moment.ISO_8601);

    // console.log(newMomentSelectedDate2.toISOString());
    // console.log(oldMomentSelectedDate2.toISOString());

    //
    // If the same date, do nothing
    // !!! This condition is important to avoid resetting (below)
    // all date configs when the model value already exists.
    //
    if ( newMomentSelectedDate2.isSame(oldMomentSelectedDate2) ) {
      return;
    } // if



    //
    // Delete existing time, seattemplate, person count
    //

    this.form.get('eventselectedtime')?.setValue('');
    delete this.model?.eventselectedtime;
    this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );


    this.formDynamicsService.setSeatTemplate( this.getAvailableSeatTemplate(null) );


    this.form.get('personcount')?.setValue('');
    delete this.model?.personcount;
    this.options.formState.isPersonCountHiddenAsync = false;
    this.options.formState.availableseats = null;


    // Load the time options corresponding to the date and the event
    const selectedEventValue = this.model?.event?.value;
    // console.log(selectedEventValue);

    const selectedDate2 = value;  //this.model?.eventselecteddate2;
    // console.log(selectedDate2);

    if (!selectedEventValue || !selectedDate2) {
      this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );
      delete this.model?.eventselectedtime;
      this.ref.detectChanges();
      return;
    } //

    // const ngbDate = this.dateService.convertDateStringToNgbDateStruct(selectedDate2, "DD/MM/YYYY hh:mm:ss");

    const ngbDate = this.dateService.convertMomentDateToNgbDateStruct(newMomentSelectedDate2);

    const times$ = this.eventTimeService.getTimesOfEventAndDate(ngbDate, selectedEventValue);

    times$.subscribe({
      next: results => {

        const timeOptions = results?.results;
        // console.log(timeOptions);

        // Load the corresponding time options.
        if (timeOptions?.length !== 0) {
          this.formDynamicsService.setEventTimeOptions([
            ...this.getDefaultSelectOption(),
            ...timeOptions
          ]);
        } else {

          this.form.get('eventselectedtime')?.setValue('');
          delete this.model?.eventselectedtime;
          this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );

          this.formDynamicsService.setSeatTemplate( this.getAvailableSeatTemplate(null) );

          this.form.get('personcount')?.setValue('');
          delete this.model?.personcount;
          this.options.formState.isPersonCountHiddenAsync = false;
          this.options.formState.availableseats = null;

        } // if
      }
    });

  }


  onInitSelectedDateNgx = (field: FormlyFieldConfig) => {
    // console.log('*** onInitSelectedDateNgx');

    const selectedEvent = this.model?.event?.value;
    // console.log(event);

    if (!selectedEvent) {
      return;
    } // if

    // const eventDate = this.model?.eventselecteddate;
    // console.log(eventDate);


    const selectedEventDate2UnknownFormat = this.model?.eventselecteddate2;
    // console.log('-> selected date');
    // console.log(selectedEventDate2UnknownFormat);

    const selectedEventDate2MomentLocalTimezone = this.dateService
      .convertDateStringToMoment(selectedEventDate2UnknownFormat, moment.ISO_8601);
    // console.log('-> selected date in moment local timezone');
    // console.log(selectedEventDate2MomentLocalTimezone);

    const selectedEventDate2ISOString = this.dateService
      .convertMomentLocalTimezoneToISOString(selectedEventDate2MomentLocalTimezone);
    // console.log('-> selected date ISO string');
    // console.log(selectedEventDate2ISOString);

    // console.log(eventDate2);

    //
    // Check if the event and eventDate2 are compatible
    //
    const checkExistingEventDate$ = this.eventDateService.getDatesForEvent(selectedEvent);
    checkExistingEventDate$.subscribe({
      next: results => {

        // Convert dates from string date to date Object
        // Enable the date of the calendar according to the received value of the event
        const eventDatesNgbDates = results.results;
        // console.log(eventDatesNgbDates);
        let eventDatesMomentLocalTimezone = [];
        if ( !_.isArray(eventDatesNgbDates) || eventDatesNgbDates.length === 0 ) {
          // field.props.datesEnabled = [new Date('xxx')]; // disable the datepicker with invalid date.
          this.formDynamicsService.setSelectedDateEnabled([new Date('xxx')]); // disable the datepicker with invalid date.
        } else {

          // Convert array of NgbDate struct to array of moment.
          eventDatesMomentLocalTimezone = this.dateService.convertArrayOfNgbDateStructToArrayOfMomentLocalTimezone(eventDatesNgbDates);
          // console.log(eventDatesMomentLocalTimezone);

          // momentLocalTimeZoneDate = this.dateService.convertArrayOfDateStringsToArrayOfMoment(dates, moment.ISO_8601);
          // field.props.datesEnabled = momentLocalTimeZoneDate;
          this.formDynamicsService.setSelectedDateEnabled(eventDatesMomentLocalTimezone);

        } // if

        // If the set value does not exist among the received value, reinit the date ant time to null.
        // const eventDate2 = this.model?.eventselecteddate2;
        // console.log(selectedEventDate2DateISOString);
        // console.log(eventDatesNgbDates);
        // const unixEventDate2 = this.dateService
        //   .convertDateStringToUnix(selectedEventDate2DateISOString, moment.ISO_8601);
        // const unixDates = this.dateService
        //   .convertArrayOfDateStringsToArrayOfUnix(eventDatesNgbDates, moment.ISO_8601);
        // console.log(unixEventDate2);
        // console.log(unixDates);
        //

        const eventDatesDateISOString = this.dateService
          .convertArrayOfMomentLocalTimezoneToArrayOfISOString(eventDatesMomentLocalTimezone);

        // console.log('-> selected date');
        // console.log(selectedEventDate2MomentLocalTimezone);
        //
        // console.log('-> list of dates');
        // console.log(eventDatesDateISOString);

        // if ( !_.includes(unixDates, unixEventDate2) ) {
        if ( !_.includes(eventDatesDateISOString, selectedEventDate2ISOString) ) {

          // this.form.get('eventselecteddate')?.setValue('');
          // this.form.get('eventselecteddate2')?.setValue('');
          // this.form.get('eventselectedtime')?.setValue('');
          //
          // // Reset value for model
          // // delete this.model?.eventselecteddate;
          // delete this.model?.eventselecteddate2;
          // delete this.model?.eventselectedtime;


          this.form.get('eventselecteddate2')?.setValue(null);
          delete this.model?.eventselecteddate2;


          this.form.get('eventselectedtime')?.setValue('');
          delete this.model?.eventselectedtime;
          this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );


          this.formDynamicsService.setSeatTemplate( this.getAvailableSeatTemplate(null) );


          this.form.get('personcount')?.setValue('');
          delete this.model?.personcount;
          this.options.formState.isPersonCountHiddenAsync = false;
          this.options.formState.availableseats = null;


        } // if

      }
    });

  }

  onShowDatePicker = (field: FormlyFieldConfig) => {
    // console.log('*** onShowDatePicker');


  }

  onHiddenDatePicker = (field: FormlyFieldConfig) => {
    // console.log('*** onHiddenDatePicker');
    //
    //
    // // console.log(this.form.get('eventselecteddate2').value);
    // let eventSelectedDate2 = this.form.get('eventselecteddate2').value;
    // const eventSelectedDate2Model = this.model?.eventselecteddate2;
    //
    // // Verify if there is a change
    // console.log('-> change verification');
    // const selectedDate2FromForm = this.dateService.convertDateStringToMoment(eventSelectedDate2, moment.ISO_8601);
    // const selectedDate2FromModel = this.dateService.convertDateStringToMoment(eventSelectedDate2Model, moment.ISO_8601);
    // console.log(selectedDate2FromForm);
    // console.log(selectedDate2FromModel);
    //
    //
    //
    // console.log('-> timezone process');
    // // Sometimes there is a local conversion of the value using another hour.
    // //
    // console.log(eventSelectedDate2);
    // console.log(this.model?.eventselecteddate2);
    // console.log(this.dateService.convertFromTypescriptDateToNgbDateStruct(eventSelectedDate2, moment.ISO_8601));
    // console.log(this.dateService.convertDateStringToMoment(eventSelectedDate2).toISOString());
    // console.log(this.dateService.convertDateStringToMoment(eventSelectedDate2).add('1', 'hour').toISOString());
    //
    // eventSelectedDate2 = this.dateService.convertDateStringToMoment(eventSelectedDate2).add('1', 'hour');
    //
    //
    // this.onChangeDateNgx(eventSelectedDate2);


  }

  onChangeSelectedTime = (field: FormlyFieldConfig) => {

    // console.log('*** onChangeSelectedTime');

    const selectEventValue = this.model?.event?.value;
    // console.log(selectEventValue);

    const selectedEventDate2 = this.model?.eventselecteddate2;
    // console.log(selectedEventDate2);

    const selectedEventTime = this.model?.eventselectedtime;
    // console.log(selectedEventTime);

    this.form.get('personcount')?.setValue('');
    delete this.model?.personcount;


    if (!selectEventValue || !selectedEventDate2 || !selectedEventTime) {
      this.formDynamicsService.setSeatTemplate( this.sanizizer.bypassSecurityTrustHtml(this.getAvailableSeatTemplate(null)) );
      this.formDynamicsService.setIsPersonCountHidden('false');
      return;
    } // if

    const eventDate2InNgbStruct = this.dateService.convertDateStringToNgbDateStruct(selectedEventDate2, "DD/MM/YYYY hh:mm:ss");

    const availableseat$ = this.eventTimeService.getAvailableSeats(eventDate2InNgbStruct, selectedEventTime, selectEventValue);
    availableseat$.subscribe({
      next: results => {
        const remainingSeats = results?.results;

        this.availableseats = remainingSeats; // for the validator

        // console.log('remaining seats = ' + remainingSeats);
        this.formDynamicsService.setSeatTemplate( this.sanizizer.bypassSecurityTrustHtml(this.getAvailableSeatTemplate(remainingSeats)) );

        if (remainingSeats === null) {
          this.options.formState.isPersonCountHiddenAsync = false;
          this.options.formState.availableseats = null;
        } else {
          this.options.formState.isPersonCountHiddenAsync = remainingSeats === 0; // if
          this.options.formState.availableseats = remainingSeats;
        } // if

        // Revalidate the form
        // this.form.get('personcount').markAsTouched();
        // this.form.markAllAsTouched();
        // this.ref.detectChanges();

      }
    });

  }

  onInitSelectedTime = (field: FormlyFieldConfig) => {
    // console.log('*** onInitSelectedTime');

    const event = this.model?.event?.value;
    // console.log(event);

    // const eventDate = this.model?.eventselecteddate;
    // console.log(eventDate);

    const eventDate2 = this.model?.eventselecteddate2;
    // console.log(eventDate2);

    const eventTime = this.model?.eventselectedtime;
    // console.log(eventTime);

    if (!event || !eventDate2) {
      this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );
      delete this.model?.eventselectedtime;
      this.ref.detectChanges();
      return;
    } // if

    //
    // Init process
    //
    // From event, eventDate2, get the list of available times and set the list of time options
    const eventDate2InNgbStruct = this.dateService.convertDateStringToNgbDateStruct(eventDate2, "DD/MM/YYYY hh:mm:ss");
    const times$ = this.eventTimeService.getTimesOfEventAndDate(eventDate2InNgbStruct, event);
    times$.subscribe({
      next: results => {

        const timeOptions = results?.results;
        // console.log(timeOptions);

        // Load the corresponding time options.
        if (timeOptions?.length !== 0) {
          this.formDynamicsService.setEventTimeOptions([
            ...this.getDefaultSelectOption(),
            ...timeOptions
          ]);
        } else {
          this.formDynamicsService.setEventTimeOptions( this.getDefaultSelectOption() );
        } // if

        // Remove model time if the model is not part of the timeoptions
        let isOneOfTimeOptions = false;
        for (const timeOption of timeOptions) {
          if (_.isEqual(timeOption.value, eventTime)) {   // The timeoption value is important as time option is NgbTimeStruct
            isOneOfTimeOptions = true;
            break;
          } // if
        } // if
        if (!isOneOfTimeOptions) {
          this.form.get('eventselectedtime').setValue('');
          delete this.model?.eventselectedtime;

          this.formDynamicsService.setSeatTemplate( this.getAvailableSeatTemplate(null) );

          this.form.get('personcount')?.setValue('');
          delete this.model?.personcount;
          this.options.formState.isPersonCountHiddenAsync = false;

        } // if

        this.ref.detectChanges();

      }
    });

  }

  onChangePersonCount = (field: FormlyFieldConfig, event: any): void => {
    // console.log('onChangePersonCount');
  }


  onInitRegisteredPersons = (field: FormlyFieldConfig) => {

    // console.log('*** onInitRegisteredPersonsTemplate');

    // Check from model if the number of registered persons is possible.
    // If not, show the warning
    // If yes, show the normal form
    const selectEventValue = this.model?.event?.value;
    // console.log(selectEventValue);

    const selectedEventDate2 = this.model?.eventselecteddate2;
    // console.log(selectedEventDate2);

    const selectedEventTime = this.model?.eventselectedtime;
    // console.log(selectedEventTime);

    if (!selectEventValue || !selectedEventDate2 || !selectedEventTime) {
      this.formDynamicsService.setSeatTemplate( this.sanizizer.bypassSecurityTrustHtml(this.getAvailableSeatTemplate(null)) );
      this.options.formState.isPersonCountHiddenAsync = false;
      return;
    } // if

    const eventDate2InNgbStruct = this.dateService.convertDateStringToNgbDateStruct(selectedEventDate2, "DD/MM/YYYY hh:mm:ss");

    const availableseat$ = this.eventTimeService.getAvailableSeats(eventDate2InNgbStruct, selectedEventTime, selectEventValue);
    availableseat$.subscribe({
      next: results => {
        const remainingSeats = results?.results;
        // console.log('remaining seats = ' + remainingSeats);
        this.formDynamicsService.setSeatTemplate( this.sanizizer.bypassSecurityTrustHtml(this.getAvailableSeatTemplate(remainingSeats)) );

        if (remainingSeats === null) {
          this.options.formState.isPersonCountHiddenAsync = false;
        } // if
        this.options.formState.isPersonCountHiddenAsync = remainingSeats === 0; // if
      }
    });

  }

  getAvailableSeatTemplate(remainingseats: number|null): string {
    if (remainingseats === null) {
      return '<h6 class="mt-3">Nombre de places disponibles / Place available: -</h6>';
    } // if
    if (remainingseats <= 0) {
      return '<h6 class="mt-3 text-danger" >Plus de places disponibles / No more place available</h6>';
    } // if
    return '<h6 class="mt-3">Nombre de places disponibles / Place available: ' + remainingseats + '</h6>';
  }



  setEventDetail(): string {

    // console.log(this.model?.event);

    const eventInfo = {
      atelier_horloger: {
        title: 'ATELIERS HORLOGERS',
        detail: 'Animés par l’école d’horlogerie de Genève, ces ateliers d’initiation à l’horlogerie permettent\n' +
          'd’assembler les composants horlogers et de s’immerger au coeur du mécanisme d’une montre.\n' +
          'Du mardi au dimanche à 11h, 13h et 15h (60 min). Tout public dès 10 ans.'
      },
      visite_guidee_exposition: {
        title: 'Visites guidées de l’exposition',
        detail: 'Du mardi au vendredi à 12h / samedi et dimanche à 14h, 15h et 16h (50 min). Tout public dès\n' +
          '15 ans.'
      },
      visite_guidee_fallet: {
        title: 'Visite de l’exposition sous la conduite d’Estelle Fallet, conservatrice en cheffe au Musée d’Art et\n' +
          'd’Histoire.',
        detail: 'Évènement réservé aux membres de la SAMAH.\n' +
          'Le mardi 7 novembre à 12h30 (60 min).'
      }
    };

    if (eventInfo.hasOwnProperty(this.model?.event?.value)) {
      // console.log(this.model?.event?.value);
      return `
      <div class="card mt-3">
        <div class="card-body">
          <h5 class="card-title">` + eventInfo[this.model?.event?.value]?.title + `</h5>
          <p class="card-text">` + eventInfo[this.model?.event?.value].detail + `</p>
        </div>
      </div>
    `;
    } // if


    return '';

  }

  gotoRecap(): void {
    if (this.form.valid) {
      this.formstep = 'recap';
    } else {
      this.formstep = '';
    } // if
  }

  submit(): void {

    // Do not submit two times if already submitted
    if (this.submitInProgress) {
      return;
    } // if

    this.submitInProgress = true;

    // Clone the model and modify eventselecteddate2 to a NgbStructDate
    const registration = _.cloneDeep(this.model);

    registration.eventselecteddate2 =
      this.dateService.convertFromTypescriptDateToNgbDateStruct(
        this.model?.eventselecteddate2, 'DD/MM/YYYY hh:mm:ss');

    this.registrationService.postRegistrationData(registration).subscribe({
      next: (data) => {

        this.submitInProgress = true;

        const results = data?.results;

        this.successmessage = results?.successmessage;
        this.errormessage = results?.errormessage;

        this.formstep = 'result';
        // Reset the model to avoid multiple submission
        this.model = {};

        this.submitInProgress = false;

      },
      error: (err) => {
        // Nothing to do in case of error
        // The model is not reinitialized.
        this.servererror = true;

        this.submitInProgress = false;
      }
    });

  }

  gotoForm(): void {
    this.formstep = '';
    this.servererror = false;
  }

  reinitForm(): void {
    this.servererror = false;
    window.location.reload();
  }

  hideEventSelectedTimeLogic = (field: FormlyFieldConfig): boolean => {
    switch (this.model.event) {
      case 'offre_edu_atelier_initiation_horlogerie':
      case 'offre_edu_atelier_engrenage':
      case 'offre_edu_visite_guidee_exposition':
        return true;
    } // switch
    return false;
  }

  eventCompareWith = (o1: any, o2: any): boolean => {
    if (!o1 || !o2) {
      return false;
    } // if

    return o1?.value === o2?.value;
  }


}
