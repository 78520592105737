import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Params} from '../shared/params';
import {Observable} from 'rxjs';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class EventDateService {

  private serverRoot: string;

  constructor(
    private http: HttpClient,
    private params: Params
  ) {
    this.serverRoot = this.params.getBffapiroot();
  }

  isExistingEventDate(date: NgbDateStruct, event: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = '/v1/event-dates?activity=' + event + '&day=' + date.day.toString() + '&month=' + date.month.toString() + '&year=' + date.year.toString();
    return this.http.get(this.serverRoot + url);
  }

  isExistingEventDateSync(date: NgbDateStruct, event: string): boolean {
    // tslint:disable-next-line:max-line-length
    const url = '/v1/event-dates?activity=' + event + '&day=' + date.day.toString() + '&month=' + date.month.toString() + '&year=' + date.year.toString();
    const xhr = new XMLHttpRequest();

    let result = null;
    xhr.open('GET', this.serverRoot + url, false); // Set the third parameter to 'false' for synchronous request
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        result = JSON.parse(xhr.responseText);
      } // if
    };
    xhr.send();

    console.log(result?.results);

    return result?.results !== 'not-exist';

  }

  getDatesForEvent(event: string): Observable<any> {
    // console.log('f getDatesForEvent');
    const url = '/v1/event-dates/' + event;
    return this.http.get(this.serverRoot + url);
  }

  getEvents(eventkey: string = ''): Observable<any> {
    let url = '/v1/admin/events';
    if (eventkey) {
      url = '/v1/admin/events?eventkey=' + eventkey;
    }
    return this.http.get(this.serverRoot + url);
  }

  // createEvent(): Observable<any> {
  //
  // }
  //
  readEvent(eventid: string): Observable<any> {
    const url = '/v1/admin/events/' + eventid;
    return this.http.get(this.serverRoot + url);
  }
  //
  // updateEvent(eventid: string): Observable<any> {
  //
  // }
  //
  // deleteEvent(eventid: string): Observable<any> {
  //
  // }

}
