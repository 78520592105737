import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-registration-result',
  templateUrl: './registration-result.component.html',
  styleUrls: ['./registration-result.component.scss']
})
export class RegistrationResultComponent {

  @Input() successmessage: string = '';
  @Input() errormessage: string = '';

}
