import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-event-ellipsis',
  templateUrl: './event-ellipsis.component.html',
  styleUrls: ['./event-ellipsis.component.scss']
})
export class EventEllipsisComponent implements OnInit {
  @Input() idvaluechild: string;
  @Input() entityroot: string;
  @Input() httpcrud: any;
  @Input() deleteEntityHandler: (idvalue) => Observable<any>;
  @Input() removePostTreatmentHandler: (idvalue, entityroot) => any;

  constructor(
    protected router: Router,
    protected http: HttpClient
  ) {
  }

  ngOnInit(): void {
  }

  toolEditEntity(): void {
    // use idvaluechild to edit the entity
    const editUrl = this.entityroot + '/' + this.idvaluechild + '/edit';
    console.log(editUrl);
    this.router.navigate([editUrl]);
  }

}
