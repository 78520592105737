<ng-template #confirmtemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm the deletion</h4>
    <button type="button" class="btn-close pull-right" aria-label="Close" (click)="closeConfirmRemoveDialog()">
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="text-left">Confirm the deletion? This operation can not be undone</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn at-ct-btn-ib-white-with-dark-bg" (click)="closeConfirmRemoveDialog()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="toolConfirmEntityDeletion()">Confirm the deletion</button>
  </div>
</ng-template>

<div class="row justify-content-center">
  <div class="btn-group" ngbDropdown container="body">
    <i class="fas fa-ellipsis-v fa-lg mouse-pointer text-secondary" ngbDropdownToggle></i>
    <div  class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
      <button class="dropdown-item" type="button" (click)="toolEditEntity()" ngbDropdownItem>Update</button>
      <button class="dropdown-item" type="button" (click)="confirmRemoveDialog(confirmtemplate)" ngbDropdownItem>Delete</button>
    </div>
  </div>
</div>
