import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Params} from '../shared/params';
import {Observable} from 'rxjs';
import {JsonState} from '../shared/shared-interface';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private serverRoot: string;
  constructor(
    private http: HttpClient,
    private params: Params
  ) {
    this.serverRoot = this.params.getBffapiroot();
  }

  postRegistrationData(model: any): Observable<any> {
    const url = '/v1/event-registration';
    return this.http.post(this.serverRoot + url, model);
  }

  cancelRegistration(registrationId: string): Observable<any>{
    const url: string = '/v1/admin/event-registration/' + registrationId + '/cancel';
    return this.http.put(this.serverRoot + url, {});
  }

}
