import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getMemoryTokenValue();

    if (token) {
      // If token, clone current request and add bearer authorization
      const cloned = request.clone({
        headers: request.headers.set('cr', token)
      });
      return next.handle(cloned);
    } else {
      return next.handle(request);
    } // if
  }
}
