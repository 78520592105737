import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IDisplayColumn} from '../../../shared/library/crud-library/crud-generic-list/crud-generic-list.component';
import {BffApi} from '../../../shared/bff-api';
import {ActivatedRoute} from '@angular/router';
import {DateService} from '../../../shared/date.service';
import {TableUtils} from '../../../shared/table-utils';
import {SharedDataService} from '../../../shared/shared-data.service';


@Component({
  selector: 'app-registration-admin',
  templateUrl: './registration-admin.component.html',
  styleUrls: ['./registration-admin.component.scss']
})
export class RegistrationAdminComponent implements OnInit {

  observabledata: Observable<any>;
  displayedcolumns: IDisplayColumn[] = [];
  idstates: {};
  eventkey: string;
  data: any;

  constructor(
    private bffApi: BffApi,
    private route: ActivatedRoute,
    private dateService: DateService,
    private sharedDataService: SharedDataService
  ) {
    this.sharedDataService.idstateobservable$.subscribe((data) => {
      this.idstates = data;
    });
  }

  ngOnInit(): void {

    const eventkey = this.route.snapshot.paramMap.get('eventkey');
    this.eventkey = eventkey;

    this.observabledata = this.bffApi.getEventPeople(eventkey);

    this.displayedcolumns = [
      // {columnName: 'id', label: 'ID'},
      {columnName: 'flag', label: 'Flag'},
      {columnName: 'mergedcolumn1', label: 'Nom complet et adresse'},
      {columnName: 'peoplenumber', label: 'Nombre de personnes'},
      {columnName: 'created', label: 'Date d\'inscription'},
      {columnName: 'mergedcolumn2', label: 'Contacts'},
      // {columnName: 'phonenumber', label: 'Téléphone'},
      // {columnName: 'emailaddress', label: 'Téléphone'},
      {columnName: 'invitedpeople', label: 'Invités'},

    ] as IDisplayColumn[];
  }

  getObservableData(): Observable<any> {
    return this.observabledata;
  }

  getPostTreatmentCallback(): any {
    return (data) => {
      console.log(data);

      const idstates = {};
      for (const item of data['results']) {
        idstates[item['id']] = item['flag'];
      } // for
      this.sharedDataService.setRegistrationListStates(idstates);

      return data['results'];
    };
  }

  dateConvertion(dateValue: any): string {
    return this.dateService.formatDate(dateValue, 'YYYY-MM-DD HH:mm:ss', 'DD.MM.YYYY HH:mm:ss');
  }

  exportNewChronoExcel(): void {
    const observableDataSource = this.bffApi.getEventPeople(
      this.eventkey, 'flag=new', 'sort=created:asc');
    this.treatExcelExport(observableDataSource);
  }

  exportToExcel(): void {
    const observableDataSource = this.bffApi.getEventPeople(this.eventkey, '', 'sort=created:asc');
    this.treatExcelExport(observableDataSource);
  }

  treatExcelExport(observableDataSource: Observable<any>): void {
    // Recall the link
    observableDataSource.subscribe((data) => {
      const datasource = data.results;

      // Reformat invited people
      const treatedDatasource = [];
      for (const item of datasource) {

        treatedDatasource.push({
          // ID: item.id,
          Nombre: item.peoplenumber,
          DateInscription: this.dateConvertion(item.created),
          Titre: item.title,
          Prenom: item.firstname,
          Nom: item.lastname,
          Email: item.emailaddress,
          Societe: item.company,
          Fonction: item.function,
          Telephone: item.phonenumber,
          Adresse1: item.addressline1,
          Adresse2: item.addressline2,
          Ville: item.city,
          NPA: item.zip,
          Province: item.province,
          Pays: item.country
        });

        for (const invitedItem of item.invitedpeople) {
          let invitedPeopleString = '';
          invitedPeopleString += invitedItem.title + ' ' + invitedItem.firstname + ' ' + invitedItem.lastname;
          treatedDatasource.push({
            Titre: invitedItem.title,
            Prenom: invitedItem.firstname,
            Nom: invitedItem.lastname,
            Email: invitedItem.emailaddress,
          });
        } // for
      } // for

      TableUtils.exportArrayToExcel(treatedDatasource);
    });
  }

}
