<h2>Ateliers</h2>
<h4>Ajouter/Editer atelier</h4>

<div class="row">
  <div class="col-lg-6 col-sm-12 mt-3">
    <form *ngIf="formstep==''" [formGroup]="form" (ngSubmit)="submit()">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
      <button class="btn btn-light mx-4">Retourner / Back</button>
      <button class="btn btn-primary" type="submit">Ajouter / Editer</button>
    </form>
  </div>
</div>

