import {Component, Input, TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {RegistrationService} from '@services/registration.service';

@Component({
  selector: 'app-registration-ellipsis',
  templateUrl: './registration-ellipsis.component.html',
  styleUrls: ['./registration-ellipsis.component.scss']
})
export class RegistrationEllipsisComponent {

  private confirmDialog: BsModalRef;
  @Input() eventid: string;
  @Input() idvaluechild: string;
  @Input() entityroot: string;
  @Input() httpcrud: any;
  @Input() deleteEntityHandler: (idvalue) => Observable<any>;
  @Input() removePostTreatmentHandler: (idvalue, entityroot) => any;

  constructor(
    protected router: Router,
    protected http: HttpClient,
    private modalService: BsModalService,
    private registrationService: RegistrationService
  ) {
  }

  ngOnInit(): void {
  }

  cancelRegistration(): void {

    // console.log(this.eventid);
    // console.log(this.idvaluechild);
    // console.log(this.entityroot);

    this.registrationService.cancelRegistration(this.idvaluechild).subscribe({
      next: value => {
        this.confirmDialog.hide();
        window.location.href = this.entityroot + '/' + this.eventid + '/edit';
      },
      error: err => {
        this.confirmDialog.hide();
      }
    });

  }

  closeModal(): void {
    this.confirmDialog.hide();
  }

  openModal(confirmCancellationTemple: TemplateRef<any>): void {
    this.confirmDialog = this.modalService.show(confirmCancellationTemple);
  }

  toggleDropdown(): void {

  }
}
