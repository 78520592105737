import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Params} from '../shared/params';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private serverRoot: string;

  constructor(
    private http: HttpClient,
    private params: Params
  ) {
    this.serverRoot = this.params.getBffapiroot();
  }

  getActivities(): Observable<any> {
    const url = '/v1/admin/activities';
    return this.http.get(this.serverRoot + url);
  }

  // createActivita(): Observable<any> {
  //
  // }

  readActivity(activityid: string): Observable<any> {
    const url = '/v1/admin/activities/' + activityid;
    return this.http.get(this.serverRoot + url);
  }

  // updateActivity(activityid: string): Observable<any> {
  //
  // }
  //
  // deleteActivity(activityid: string): Observable<any> {
  //
  // }

}
