import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isloggedin$: BehaviorSubject<boolean>;
  expirationTokenKey: string;
  tokenKey: string;

  constructor(
    private router: Router,
  ) {
    this.isloggedin$ = new BehaviorSubject<boolean>(false);
    this.tokenKey = 'gphgToken';
    this.expirationTokenKey = 'gphgExpirationToken';
  }

  login(username: string, password: string): void {
    const token = username + ':' + password;

    const sessionDuration = 3600; // 1h duration by default
    const expiresAt = moment().add(sessionDuration, 'second');
    const stringifyExpire = JSON.stringify(expiresAt.valueOf());

    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.expirationTokenKey, stringifyExpire);

  }

  getMemoryTokenValue(): string {
    // The token for gphg is usename:password
    if (localStorage.getItem(this.tokenKey)) {
      return localStorage.getItem(this.tokenKey);
    } // if
    return '';
  }

  getMemoryExpirationTokenValue(): any {
    const expirationData = localStorage.getItem(this.expirationTokenKey);
    const expiresAt = JSON.parse(expirationData);
    return moment(expiresAt);
  }

  setLoggedin(flag: boolean): void {
    this.isloggedin$.next(flag);
  }

  checkLocalSession(): void {
    if (!localStorage.getItem(this.expirationTokenKey)) {
      this.setLoggedin(false);
    } // if
    if (moment().isBefore(this.getMemoryExpirationTokenValue())) {
      this.setLoggedin(true);
    } else {
      this.setLoggedin(false);
    } // if
  }

  isLocalSessionActive(): boolean {
    if (!localStorage.getItem(this.expirationTokenKey)) {
      return false;
    } // if
    if (moment().isBefore(this.getMemoryExpirationTokenValue())) {
      return true;
    } else {
      return false;
    } // if
  }

  logout(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.expirationTokenKey);
    this.setLoggedin(false);
    this.router.navigate(['/login']);
  }


}
