import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvailableSeatsService {

  constructor() { }

  getAvailableSeats(event, eventselecteddate, eventselectedtime): Observable<any> {
    if (!event || !eventselecteddate || !eventselectedtime) {
      console.log('registered person not availanle');
      return of(null);
    } // if

    switch (event) {
      case 'atelier_horloger':
        return of({remainingseats: 3});

      case 'visite_guidee_exposition':
        return of({remainingseats: 0});

      default:
        return of(null);
    } // switch
  }

}
