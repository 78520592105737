<h2>Evénements</h2>

<button type="button" class="btn btn-light mt-3" (click)="gotoAdd()">
  <i class="fa-regular fa-square-plus fa-lg"></i>
  Ajouter un nouvel événement
</button>

<ng-template #valueTemplate
             let-valueinput="value"
             let-elementinput="element"
             let-columninput="column">

  <ng-container *ngIf="columninput['columnName']==='date'">
    <span>{{ valueinput }}</span>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='seats'">
    {{ valueinput }} <span *ngIf="valueinput == 0 " class="badge rounded-pill text-bg-danger">Complet</span>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='totalconfirmedseats'">
    <ng-container *ngIf="valueinput != 0 " >
      <h4 >{{valueinput}} <span class="mat-small">pl./s.</span></h4>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='registrationcount'">
    <ng-container *ngIf="valueinput != 0 " >
      <h4 >{{valueinput}} <span class="mat-small">inscr.</span></h4>
    </ng-container>

  </ng-container>

</ng-template>


<app-crud-generic-list [observabledata]="getObservableData()"
                       [displayedColumns]="displayedcolumns"
                       [postTreatmentCallback]="getPostTreatmentCallback()"
                       [idColumnName]="'id'"
                       [entityroot]="entityroot"
                       [pageSizeOptions]="[100, 200]"
                       [specificColumnsTemplates]="[
                        {key: 'seats', template:valueTemplate},
                        {key: 'totalconfirmedseats', template:valueTemplate},
                        {key: 'registrationcount', template:valueTemplate}
                       ]"
>
  <ng-template appToolGrid let-id="idvalue">
    <app-event-ellipsis
      [entityroot]="entityroot"
      [idvaluechild]="id">
    </app-event-ellipsis>
  </ng-template>
</app-crud-generic-list>
