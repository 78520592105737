import {FieldType, FieldTypeConfig} from '@ngx-formly/core';
import {Component} from '@angular/core';
import {FormlyFieldNgSelect} from './ng-select-type.components';
import {NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'formly-date-picker',
  template: `

    <style>
      .weekend {
        background-color: #f0ad4e;
        border-radius: 1rem;
        color: white;
      }
    </style>

<!--    <ng-template-->
<!--      #customDay-->
<!--      let-date-->
<!--      let-currentMonth="currentMonth"-->
<!--      let-selected="selected"-->
<!--      let-disabled="disabled"-->
<!--      let-focused="focused"-->
<!--    >-->
<!--    <span-->
<!--      class="custom-day"-->
<!--      [class.weekend]="isWeekend(date)"-->
<!--      [class.focused]="focused"-->
<!--      [class.bg-primary]="selected"-->
<!--      [class.hidden]="date.month !== currentMonth"-->
<!--      [class.text-muted]="disabled"-->
<!--        >-->
<!--        {{ date.day }}-->
<!--    </span>-->
<!--</ng-template>-->

<!--    [formControl]="formControl":  allow linking selected data to formly model -->

    <div class="input-group mb-3">
      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" ><i class="fa-regular fa-calendar"></i></button>
      <input
              class="form-control"
              placeholder="{{props.placeholder}}"
              name="dp"
              ngbDatepicker
              [formControl]="formControl"
              [startDate]="props.startDate"
              [markDisabled]="props.markDisabled"
              [minDate]="props.minDate"
              [maxDate]="props.maxDate"
              #d="ngbDatepicker"
              [readonly]="props.readonly"
              (dateSelect)="props.dateSelect($event)"
      />
    </div>
  `
})

export class FormlyFieldDatePicker extends FieldType<FieldTypeConfig>{

  constructor(private calendar: NgbCalendar) {
    super();
  }
}
