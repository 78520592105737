import { Component } from '@angular/core';
import {IDisplayColumn} from '../../../../../shared/library/crud-library/crud-generic-list/crud-generic-list.component';
import {ActivityService} from '../../../../../services/activity.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {EventDateService} from '../../../../../services/event-date.service';

@Component({
  selector: 'app-event-main',
  templateUrl: './event-main.component.html',
  styleUrls: ['./event-main.component.scss']
})
export class EventMainComponent {
  displayedcolumns: IDisplayColumn[] = [];
  entityroot: string;
  eventkey: string

  constructor(
    private eventDateService: EventDateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.eventkey = this.activatedRoute.snapshot.params.eventkey;
    this.entityroot = 'admin/' + this.eventkey + '/events';

    this.displayedcolumns = [
      {columnName: 'date', label: 'Date'},
      {columnName: 'activity', label: 'Atelier'},
      {columnName: 'eventlabel', label: 'Meta'},
      {columnName: 'initialseats', label: 'Nombre de places'},
      {columnName: 'seats', label: 'Places restantes'},
      {columnName: 'totalconfirmedseats', label: "Places confirmées"},
      {columnName: 'registrationcount', label: "Nombre d'inscriptions"},

    ] as IDisplayColumn[];
  }

  gotoAdd(): void {
    const addUrl = this.entityroot + '/add';
    this.router.navigate([addUrl]);
  }

  getObservableData(): Observable<any> {
    return this.eventDateService.getEvents(this.eventkey);
  }

  getPostTreatmentCallback(): any {
    return (results) => {
      return results?.results;
    };
  }
}
