import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";

@Component({
  selector: 'app-simple-ellipsis',
  templateUrl: './simple-ellipsis.component.html',
  styleUrls: ['./simple-ellipsis.component.scss']
})
export class SimpleEllipsisComponent implements OnInit {

  private confirmDialog: BsModalRef;

  @Input() idvaluechild: string;
  @Input() entityroot: string;
  @Input() httpcrud: any;
  @Input() deleteEntityHandler: (idvalue) => Observable<any>;
  @Input() removePostTreatmentHandler: (idvalue, entityroot) => any;

  constructor(protected modalservice: BsModalService,
              protected router: Router,
              protected http: HttpClient) { }

  ngOnInit(): void {
  }

  toolEditEntity() {
    // use idvaluechild to edit the entity
    const editUrl = this.entityroot+'/'+this.idvaluechild+'/edit';
    this.router.navigate([editUrl]);
  }

  toolConfirmEntityDeletion() {
    // use idvaluechild to delete the entity
    if (this.deleteEntityHandler) {
      this.deleteEntityHandler(this.idvaluechild).subscribe( (data) => {

        this.confirmDialog.hide();

        if (this.removePostTreatmentHandler) {
          this.removePostTreatmentHandler(this.idvaluechild, this.entityroot);
        } // if
      });
    } // if
  } // confirmEntityDeletion

  confirmRemoveDialog(confirmtemplate: TemplateRef<any>) {
    this.confirmDialog = this.modalservice.show(confirmtemplate);
  } // confirmRemoveDialog

  // Method called by the template
  closeConfirmRemoveDialog() {
    this.confirmDialog.hide();
  } // closeConfirmRemoveDialog



}
