

<div class="row no-gutters pt-3">
  <div class="pt-1" style="width: 100px;">
    Filter
  </div>
  <div class="col">
    <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="Search">
  </div>
</div>

<ng-template #template let-idvaluechild="idvalue" let-entityroot="entityroot">
  <app-simple-ellipsis [idvaluechild]="idvaluechild"
                       [entityroot]="entityroot"
                       [deleteEntityHandler]="deleteEntityHandler"
                       [removePostTreatmentHandler]="removePostTreatmentHandler"
  ></app-simple-ellipsis>
</ng-template>

<ng-template #standardColumnTemplate let-columninput="column" let-elementinput="element" let-valueinput="value">
    <div class="ml-2 mr-2">
      <ng-container *ngIf="columninput.isKeyColumn">
        <a [routerLink]="getEditPath( getIdColumnValue(elementinput) )">{{valueinput}}</a>
      </ng-container>
      <ng-container *ngIf="!columninput.isKeyColumn">
        {{valueinput}}
      </ng-container>
    </div>
</ng-template>

<table mat-table [dataSource]="datalistsource" matSort>

  <ng-container *ngFor="let column of allDisplayedColumns">

    <ng-container matColumnDef="{{column.columnName}}">
      <th mat-header-cell class="pl-2 pr-2"  *matHeaderCellDef>{{column.label}}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!column.template">
          <ng-container  *ngTemplateOutlet="standardColumnTemplate; context: {
            column: column,
            element:element,
            value:element[column.columnName]}">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="column.template">
          <ng-container  *ngTemplateOutlet="column.template; context: {
            column: column,
            element:element,
            value:element[column.columnName]}">
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

  </ng-container>

  <ng-container matColumnDef="tools-grid">
    <th mat-header-cell class="pl-2 pr-2 text-capitalize"  *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">

      <ng-container *ngIf="toolGridTemplate">
        <ng-template [ngTemplateOutlet]="toolGridTemplate"
                     [ngTemplateOutletContext]="{idvalue: getIdColumnValue(element), entityroot: entityroot}">
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!toolGridTemplate">
        <ng-container  *ngTemplateOutlet="template; context: {idvalue: getIdColumnValue(element), entityroot: entityroot}">
        </ng-container>
      </ng-container>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
  <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>

</table>
<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
<div class="mb-5"></div>

