import { Component } from '@angular/core';

@Component({
  selector: 'app-registration-main',
  templateUrl: './registration-main.component.html',
  styleUrls: ['./registration-main.component.scss']
})
export class RegistrationMainComponent {

}
