
<div class="card mt-5 mb-5">

  <div class="card-body">
    Merci de votre inscription. Un email vous a été envoyé à votre adresse de contact.
    Avec nos cordiaux messages.
    <br /><br />
    Thank you for registering. An email has been sent to your contact address. Kind regards.
  </div>
</div>
