

<div class="container-sm">

  <div class="row">
    <div class="col"></div>
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
    <div class="col"></div>
  </div>

  <div class="row mb-3">
    <div class="col"></div>
    <div class="col-md-12">
      <hr />
      <h6><small>© FONDATION DU GRAND PRIX D'HORLOGERIE DE GENÈVE</small></h6>
    </div>
    <div class="col"></div>
  </div>

</div>




