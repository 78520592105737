import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      return moment(date.day + '/' + date.month  + '/' + date.year, 'DD/MM/YYYY').format('DD/MM/YYYY');
    } // if
    return '';
  }
}
