import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {LoginModel} from '../../../shared/shared-interface';
import {BffApi} from '../../../shared/bff-api';
import {Router} from '@angular/router';
import {AuthService} from '../../../security/auth.service';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  form: UntypedFormGroup;
  fields: FormlyFieldConfig[];
  loginmodel: LoginModel;

  constructor(
    private bffApi: BffApi,
    private authService: AuthService,
    public router: Router,
  ) {
    this.form = new UntypedFormGroup({});
    this.fields = [];
    this.loginmodel = {
      username: '',
      password: ''
    };
  }

  ngOnInit(): void {
    this.setStaticFields();
  }

  setStaticFields(): void {
    this.fields = [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          label: 'Nom d\'utilisateur',
          required: true,
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          label: 'Mot de passe',
          required: true,
        },
      },
    ];
  }

  login(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {

      this.bffApi.postCheckLogin(this.loginmodel).subscribe(() => {
        this.authService.setLoggedin(true);
        this.authService.login(this.loginmodel.username, this.loginmodel.password);
        this.router.navigate(['/admin']);
      },
          error => {
        this.authService.setLoggedin(false);
        this.authService.logout();

      });
    } // if
  }

}
