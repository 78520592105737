import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-template',
  template: `
    <div [innerHTML]="props.htmlAsync | async">
    </div>
  `

})

export class FormlyFieldCustomTemplate extends FieldType<FieldTypeConfig> {

}
