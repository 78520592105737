<div class="row justify-content-center">
  <div class="btn-group" dropdown (onShown)="onShown()" container="body">
    <i class="fas fa-ellipsis-v fa-1x mouse-pointer text-secondary" dropdownToggle></i>
    <div  class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <button class="dropdown-item" type="button" (click)="setState('new')">
        <i class="fas fa-check " [ngClass]="{'visible':states.new, 'invisible':!states.new }"></i> New</button>
      <button class="dropdown-item" type="button" (click)="setState('inprogress')">
        <i class="fas fa-check" [ngClass]="{'visible':states.inprogress, 'invisible':!states.inprogress }"></i> En cours</button>
      <button class="dropdown-item" type="button" (click)="setState('done')">
        <i class="fas fa-check" [ngClass]="{'visible':states.done, 'invisible':!states.done }"></i> Traité</button>
    </div>
  </div>
</div>
