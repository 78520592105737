
<h2>Inscriptions {{eventkey}}</h2>

<button class="btn btn-success me-4" (click)="exportToExcel()">
  <small>Tout exporter sur excel (ordre chronologique)</small></button>

<button class="btn btn-primary" (click)="exportNewChronoExcel()">
  <small>Exporter nouvelles inscriptions (ordre chronologique)</small></button>

<ng-template #valueTemplate
             let-valueinput="value"
             let-elementinput="element"
             let-columninput="column"
>

  <ng-container *ngIf="columninput['columnName']==='mergedcolumn1'">
    <strong>{{elementinput['title']}} {{elementinput['firstname']}} {{elementinput['lastname']}}</strong>

    <ng-container *ngIf="elementinput['company']">
      <br/>{{elementinput['company']}}
    </ng-container>
    <ng-container *ngIf="elementinput['function']">
      <br/>{{elementinput['function']}}
    </ng-container>

    <br/>{{elementinput['addressline1']}}
    <ng-container *ngIf="elementinput['addressline2']">
      <br/>{{elementinput['addressline2']}}
    </ng-container>
    <br/>{{elementinput['city']}} {{elementinput['zip']}}
    <br/>{{elementinput['country']}}
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='mergedcolumn2'">
    {{elementinput['phonenumber']}}<br/>
    {{elementinput['emailaddress']}}
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='created'">
    {{dateConvertion(valueinput)}}
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='flag'">

    <ng-container *ngIf="idstates[ elementinput['id']]=== 'new'">
      <span class="badge rounded-pill text-bg-danger">New</span>
    </ng-container>
    <ng-container *ngIf="idstates[ elementinput['id']]==='done'">
      <span class="badge rounded-pill text-bg-success">Traité</span>
    </ng-container>
    <ng-container *ngIf="idstates[ elementinput['id']]==='inprogress'">
      <span class="badge rounded-pill text-bg-warning">En cours</span>
    </ng-container>
  </ng-container>



  <ng-container *ngIf="columninput['columnName']==='peoplenumber'">
    <h3>{{valueinput}}</h3>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='invitedpeople'">
    <div *ngFor="let s of valueinput">
      <div class="mb-1">
      {{s['title']}} {{s['firstname']}} {{s['lastname']}}
      <ng-container *ngIf="s['emailaddress']">
        <br/><em>{{s['emailaddress']}}</em>
      </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']!=='mergedcolumn1'
    && columninput['columnName']!=='mergedcolumn2'
    && columninput['columnName']!=='created'
    && columninput['columnName']!=='peoplenumber'
    && columninput['columnName']!=='invitedpeople'
    && columninput['columnName']!=='flag'
  ">
    {{valueinput}}
  </ng-container>

</ng-template>

<app-crud-generic-list [observabledata]="getObservableData()"
                       [displayedColumns]="displayedcolumns"
                       [postTreatmentCallback]="getPostTreatmentCallback()"
                       [idColumnName]="'id'"
                       [specificColumnsTemplates]="[
                        {key: 'flag', template:valueTemplate},
                        {key: 'mergedcolumn1', template:valueTemplate},
                        {key: 'created', template:valueTemplate},
                        {key: 'title', template:valueTemplate},
                        {key: 'firstname', template:valueTemplate},
                        {key: 'lastname', template:valueTemplate},
                        {key: 'peoplenumber', template:valueTemplate},
                        {key: 'mergedcolumn2', template:valueTemplate},
                        {key: 'invitedpeople', template:valueTemplate}
                       ]"
>
  <ng-template appToolGrid let-id="idvalue">
    <app-tag-ellipsis
      [idvaluechild]="id">
    </app-tag-ellipsis>
  </ng-template>
</app-crud-generic-list>
