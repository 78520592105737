import { Component } from '@angular/core';

@Component({
  selector: 'app-registration-add',
  templateUrl: './registration-add.component.html',
  styleUrls: ['./registration-add.component.scss']
})
export class RegistrationAddComponent {

}
