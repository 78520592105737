import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from './params';
import {GphgFormModel, JsonEventStats, JsonState, LoginModel} from './shared-interface';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BffApi {
  private serverRoot: string;

  constructor(
    private http: HttpClient,
    private params: Params)
  {
    this.serverRoot = this.params.getBffapiroot();
    console.log(this.serverRoot);
  }

  postCheckLogin(loginModel: LoginModel): Observable<any> {
    const formData = new FormData();
    const credential = loginModel.username + ':' + loginModel.password;
    const options = {
      headers: new HttpHeaders({cr: credential})
    };
    const url = '/v1/login';
    return this.http.post(this.serverRoot + url, formData, options);
  }

  checkToken(token: string): Observable<any> {
    const formData = new FormData();
    const options = {
      headers: new HttpHeaders({cr: token})
    };
    const url = '/v1/login';
    return this.http.post(this.serverRoot + url, formData, options);
  }

  postFormData(formModel: GphgFormModel): Observable<GphgFormModel> {
    const formData = new FormData();

    formData.append('peoplenumber', formModel.peoplenumber);
    formData.append('model', JSON.stringify(formModel));

    const url = '/v1/registration';
    return this.http.post<GphgFormModel>(this.serverRoot + url, formData);
  }

  getEventPeople(eventKey: string, filter?: string, sort?: string): Observable<any> {
    // Example of filter and sort variables
    // flag=new
    // sort=created:asc
    const parameters = [];
    if (filter) {
      parameters.push(filter);
    } // if
    if (sort) {
      parameters.push(sort);
    } // if

    let query = '';
    if (parameters) {
      query = '?' + parameters.join('&');
    } // if

    const url = '/v1/admin/events/' + eventKey + '/people' + query;
    return this.http.get(this.serverRoot + url);
  }

  getEventStats(eventKey: string): Observable<JsonEventStats> {
    const url = '/v1/admin/events/' + eventKey + '/stats';
    return this.http.get<JsonEventStats>(this.serverRoot + url);
  }

  getRegistrationState(registrationId: string): Observable<JsonState> {
    const url = '/v1/admin/registration/' + registrationId + '/state';
    return this.http.get<JsonState>(this.serverRoot + url);
  }

  setRegistrationState(registrationId: string, state: string): Observable<any> {
    const formData = new FormData();
    formData.append('state', state);
    const url = '/v1/admin/registration/' + registrationId + '/state';
    return this.http.post(this.serverRoot + url, formData);
  }

}
