



<div class="row justify-content-center">
  <div class="btn-group" ngbDropdown container="body">

    <div style="width: 40px; height: 40px;" class="row text-center mouse-pointer align-items-center" ngbDropdownToggle>
      <div class="col">
        <i class="fas fa-ellipsis-v fa-lg mouse-pointer text-secondary" ></i>
      </div>
    </div>

    <div  class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
      <button class="dropdown-item" type="button" (click)="toolEditEntity()" ngbDropdownItem>View registrations</button>
    </div>
  </div>
</div>
