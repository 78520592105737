import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {BffApi} from '../shared/bff-api';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard  {

  constructor(
    private bffApi: BffApi,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isLocalSessionActive()) {
      // Local session active, check remote
      const token = this.authService.getMemoryTokenValue();
      this.bffApi.checkToken(token).subscribe(() => {
        // Nothing to do as the token is ok remotely
      }, error => {
        this.authService.logout();  // Logout and redirect to login
      });
    } else {
      this.authService.logout();  // Logout and redirect to login
    }

    return true;

  }

}
