import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IDisplayColumn} from '../../../../../shared/library/crud-library/crud-generic-list/crud-generic-list.component';
import {ActivityService} from '../../../../../services/activity.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-atelier-main',
  templateUrl: './atelier-main.component.html',
  styleUrls: ['./atelier-main.component.scss']
})
export class AtelierMainComponent implements OnInit {

  displayedcolumns: IDisplayColumn[] = [];
  entityroot: string;

  constructor(
    private activityService: ActivityService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.entityroot = 'admin/atelier';

    this.displayedcolumns = [
      {columnName: 'label', label: 'Label'},
      {columnName: 'yeartag', label: 'Year tag'},
      {columnName: 'eventkey', label: 'Evénement'},
      {columnName: 'value', label: 'Clé'},
      {columnName: 'groupid', label: 'Groupe clé'},
      {columnName: 'sort', label: 'Tri'}

    ] as IDisplayColumn[];
  }

  gotoAdd(): void {
    const addUrl = this.entityroot + '/add';
    this.router.navigate([addUrl]);
  }

  getObservableData(): Observable<any> {
    return this.activityService.getActivities();
  }

  getPostTreatmentCallback(): any {
    return (results) => {
      return results?.results;
    };
  }

}
