import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'formly-field-ng-select',
  template: `
    <ng-select
      [formControl]="getFormControl()"
      [formlyAttributes]="field"
      [multiple]="props.multiple"
      [placeholder]="props.placeholder"
      [groupBy]="getGroupBy()"
      [selectableGroup]="props.selectableGroup"
      [bindLabel]="getBindLabel()"
      [bindValue]="getBindValue()"
      [items]="getOptions()"
    >
      <ng-template ng-optgroup-tmp let-item="item">
        {{ viewGroupItem(item) }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ viewItem(item)}}
      </ng-template>
    </ng-select>
  `,
})
export class FormlyFieldNgSelect extends FieldType {

  getFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  getOptions(): any[] {
    return this.props.options as any[];
  }

  getBindLabel(): string {
    if (this.props.bindLabel) {
      return this.props.bindLabel;
    }
    return 'label';
  }

  getBindValue(): string {
    if (this.props.bindValue) {
      return this.props.bindValue;
    }
    return 'value';
  }

  getGroupBy(): string {
    if (this.props.groupBy) {
      return this.props.groupBy;
    }
    return 'group';
  }

  viewGroupItem(groupitem: any): string {
    if (!groupitem) {
      return 'Undefined group';
    } // if

    return groupitem[ this.getBindLabel() ];
  }

  viewItem(item: any): string {
    if (!item) {
      return 'Undefined item';
    } // if
    return item[ this.getBindLabel() ];
  }

}
