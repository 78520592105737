

<div class="card mb-3" *ngIf="isActive===true" >

  <div class="card-body">
    <h3>INSCRIPTION / REGISTRATION</h3>
    <h4 class="mt-2">RSVP avant le 10 octobre 2024 (places limitées) / RSVP by 10 October 2024 (limited seating)</h4>
    <form [formGroup]="form" >
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
      <div class="row mt-2">
        <div class="col">
          <div *ngIf="showErrorAlert" class="alert alert-warning alert-dismissible fade show" role="alert">
            Une erreur de connexion au serveur est apparue. Veuillez réessayer
            plus tard ou contacter l'administrateur (<a href="mailto: ceremonie@gphg.org">ceremonie@gphg.org</a>) /
            Please retry later or contact the administrator (<a href="mailto: ceremonie@gphg.org">ceremonie@gphg.org</a>).
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-xl-6">
          <button
            class="btn btn-primary"
            [ngClass]="{'disabled': submitInProgress === true}"
            (click)="onSubmit()"
            type="button" >
            Confirmer l'inscription / Confirm registration</button>
        </div>
        <div class="col-xl-6">
        </div>
      </div>



    </form>
  </div>
</div>

<div *ngIf="showDebug===true" class="row">
  <div class="col mt-5">
    <pre>{{ model | json }}</pre>
  </div>
</div>

