import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

export interface Appconfig {
  bffapiroot: string;
}

@Injectable({
  providedIn: 'root'
})
export class Params {
  private appConfigs;

  constructor(private http: HttpClient) {}

  loadAppConfig(): Promise<any> {
    return this.http
      .get('assets/conf/config.json')
      .toPromise()
      .then(data => {
        this.appConfigs = data;
      });
  }

  getBffapiroot(): string {
    return this.appConfigs.bffapiroot;
  } // getBffapiroot

  getDebug(): boolean {
    return this.appConfigs.debug;
  }

  isActive(): boolean {
    return this.appConfigs.active;
  }
}
