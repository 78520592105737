import {FieldType, FieldTypeConfig} from '@ngx-formly/core';
import {Component} from '@angular/core';
import {Observable, of, withLatestFrom} from 'rxjs';

@Component({
  selector: 'formly-date-picker-ngx',
  template: `
    <div class="input-group mb-3">
      <input type="text"
             class="form-control"
             [formControl]="formControl"
             [formlyAttributes]="field"
             [placeholder]="props.placeholder"
             bsDatepicker
             [required]="props.required"
             [bsConfig]="props.bsConfig"
             [datesEnabled]="props.customDatesEnabled | async"
             [minDate]="props.minDate"
             [maxDate]="props.maxDate"
             (onShown)="props.onShown(field)"
             (onHidden)="props.onHidden(field)"
             (bsValueChange)="getBsValueChange($event)"
      >
    </div>
  `

})
export class FormlyFieldDatePickerNgx extends FieldType<FieldTypeConfig>{
  getBsValueChange(event): any {
    if (this.props.customBsValueChange) {
      return this.props.customBsValueChange(event);
    } // if
    return () => {};
  }
}
