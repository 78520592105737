import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Params} from '../shared/params';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private serverRoot: string;

  constructor(
    private http: HttpClient,
    private params: Params
  ) {
    this.serverRoot = this.params.getBffapiroot();
  }

  getActivities(yeartag: string = ''): Observable<any> {
    let url = '/v1/event-activities';
    if (yeartag) {
      url = '/v1/event-activities/' + yeartag;
    }
    return this.http.get(this.serverRoot + url);
  }
}
