import { Component, OnInit } from '@angular/core';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {UntypedFormGroup} from '@angular/forms';
import {GphgFormModel, GphgSubFormModel, ValueLabel} from '../../shared/shared-interface';
import {ActivatedRoute, Router} from '@angular/router';
import {BffApi} from '../../shared/bff-api';
import * as countries from 'i18n-iso-countries';
import {HttpClient} from '@angular/common/http';
import {LocaleData} from 'i18n-iso-countries';
import * as _ from 'lodash';
import {Params} from '../../shared/params';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  form: UntypedFormGroup;
  fields: FormlyFieldConfig[];
  formvalidity: boolean;
  model: GphgFormModel;


  options: FormlyFormOptions;
  listOfCountries: ValueLabel[];

  submitInProgress: boolean;

  showDebug: boolean = false;
  showErrorAlert: boolean = false;
  isActive: boolean = false;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private bffApi: BffApi,
    private http: HttpClient,
    private appParameters: Params
  ) {
    this.form = new UntypedFormGroup({});
    this.fields = [];
    this.formvalidity = false;
    this.model = {
      peoplenumber: '',
      title: '',
      firstname: '',
      lastname: '',
      function: '',
      company: '',
      addressline1: '',
      addressline2: '',
      city: '',
      zip: '',
      province: '',
      country: '',
      phonenumber: '',
      emailaddress: '',
      invitedpeople1: this.getSubformDefaultModel(),
      invitedpeople2: this.getSubformDefaultModel(),
      invitedpeople3: this.getSubformDefaultModel(),
      invitedpeople4: this.getSubformDefaultModel(),
      invitedpeople5: this.getSubformDefaultModel(),
      invitedpeople6: this.getSubformDefaultModel(),
      invitedpeople7: this.getSubformDefaultModel(),
      invitedpeople8: this.getSubformDefaultModel(),
      invitedpeople9: this.getSubformDefaultModel(),
    };

    this.options = {
      formState: {
        mainModel: this.model,
      },
    };
    this.submitInProgress = false;

  }

  ngOnInit(): void {

    this.showDebug = this.appParameters.getDebug();
    this.isActive = this.appParameters.isActive();

    this.http.get('assets/langs/fr.json').subscribe((data: LocaleData) => {

      countries.registerLocale(data);

      const countryIso2Dict = countries.getNames('fr');

      const pays = [];
      for (const key in countryIso2Dict) {
        // id is mandatory
        pays.push({id: countryIso2Dict[key], value: countryIso2Dict[key], label: countryIso2Dict[key]});
      } // for
      this.listOfCountries = pays;

      // Init form
      this.setStaticFields();

    });

  }

  onSubmit(): void {
    // Do not submit two times if already submitted
    if (this.submitInProgress) {
      return;
    } // if
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.submitInProgress = true;
      this.bffApi.postFormData(this.model).subscribe({
        next: value => {
          this.submitInProgress = false;
          this.showErrorAlert = false;
          this.router.navigate(['/inscription-ceremonie/succes']);
        },
        error: err => {
          this.submitInProgress = false;
          this.showErrorAlert = true;
        }
      });

    }
  }

  hideField = (field: FormlyFieldConfig): boolean => {

    const hideMap = {
      1: {
        invitedpeople1: true,
        invitedpeople2: true,
        invitedpeople3: true,
        invitedpeople4: true,
        invitedpeople5: true,
        invitedpeople6: true,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      2: {
        invitedpeople1: false,
        invitedpeople2: true,
        invitedpeople3: true,
        invitedpeople4: true,
        invitedpeople5: true,
        invitedpeople6: true,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      3: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: true,
        invitedpeople4: true,
        invitedpeople5: true,
        invitedpeople6: true,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      4: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: true,
        invitedpeople5: true,
        invitedpeople6: true,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      5: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: false,
        invitedpeople5: true,
        invitedpeople6: true,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      6: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: false,
        invitedpeople5: false,
        invitedpeople6: true,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      7: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: false,
        invitedpeople5: false,
        invitedpeople6: false,
        invitedpeople7: true,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      8: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: false,
        invitedpeople5: false,
        invitedpeople6: false,
        invitedpeople7: false,
        invitedpeople8: true,
        invitedpeople9: true,
      },
      9: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: false,
        invitedpeople5: false,
        invitedpeople6: false,
        invitedpeople7: false,
        invitedpeople8: false,
        invitedpeople9: true,
      },
      10: {
        invitedpeople1: false,
        invitedpeople2: false,
        invitedpeople3: false,
        invitedpeople4: false,
        invitedpeople5: false,
        invitedpeople6: false,
        invitedpeople7: false,
        invitedpeople8: false,
        invitedpeople9: false,
      }
    };

    const peopleNumber = this.model?.peoplenumber;

    if (peopleNumber === '') {
      return true;
    } // if

    return hideMap[peopleNumber][field.key];

  }

  getOptionsNumberOfPersons(): ValueLabel[] {

    const inscriptionUrls = [
      '/2021/inscription-ceremonie',
      '/2022/inscription-ceremonie',
      '/2023/inscription-ceremonie',
      '/2024/inscription-ceremonie'
    ];
    const inscriptionGroup2Urls = [
      '/2021/groupe2/inscription-ceremonie',
      '/2022/groupe2/inscription-ceremonie',
      '/2023/groupe2/inscription-ceremonie',
      '/2024/groupe2/inscription-ceremonie'
    ];
    const inscriptionGroup5Urls = [
      '/2021/groupe5/inscription-ceremonie',
      '/2022/groupe5/inscription-ceremonie',
      '/2023/groupe5/inscription-ceremonie',
      '/2024/groupe5/inscription-ceremonie'
    ];
    const inscriptionGroup10Urls = [
      '/2021/groupe10/inscription-ceremonie',
      '/2022/groupe10/inscription-ceremonie',
      '/2023/groupe10/inscription-ceremonie',
      '/2024/groupe10/inscription-ceremonie'
    ];

    if ( _.includes(inscriptionUrls, this.router.url) ) {
      return [
        {value: '', label: 'Veuillez sélectionner / Please select'},
        {value: '1', label: '1'}
      ];
    } // if

    if ( _.includes(inscriptionGroup2Urls, this.router.url) ) {
      return [
        {value: '', label: 'Veuillez sélectionner / Please select'},
        {value: '1', label: '1'},
        {value: '2', label: '2'},
      ];
    } // if

    if ( _.includes(inscriptionGroup5Urls, this.router.url) ) {
      return [
        {value: '', label: 'Veuillez sélectionner / Please select'},
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'}
      ];
    } // if

    if ( _.includes(inscriptionGroup10Urls, this.router.url) ) {
      return [
        {value: '', label: 'Veuillez sélectionner / Please select'},
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'},
        {value: '6', label: '6'},
        {value: '7', label: '7'},
        {value: '8', label: '8'},
        {value: '9', label: '9'},
        {value: '10', label: '10'},
      ];
    } // if


  }

  getOptionsOfTitles(): ValueLabel[] {
    return [
        {value: '', label: 'Veuillez sélectionner'},
        {value: 'Monsieur', label: 'Monsieur'},
        {value: 'Madame', label: 'Madame'},
    ];
  }

  getSubformDefaultModel(): GphgSubFormModel {
    return {
      title: '',
      firstname: '',
      lastname: '',
      emailaddress: '',
    };
  }



  getOptionsOfEuropeanCountries(): ValueLabel[] {
    return [
      {value: '', label: 'Veuillez sélectionner'},
      {value: 'Albanie', label: 'Albanie'},
      {value: 'Allemagne', label: 'Allemagne'},
      {value: 'Andorre', label: 'Andorre'},
      {value: 'Arménie', label: 'Arménie'},
      {value: 'Autriche', label: 'Autriche'},
      {value: 'Azerbaïdjan', label: 'Azerbaïdjan'},
      {value: 'Belgique', label: 'Belgique'},
      {value: 'Biélorussie', label: 'Biélorussie'},
      {value: 'Bosnie-Herzégovine', label: 'Bosnie-Herzégovine'},
      {value: 'Bulgarie', label: 'Bulgarie'},
      {value: 'Chypre', label: 'Chypre'},
      {value: 'Croatie', label: 'Croatie'},
      {value: 'Danemark', label: 'Danemark'},
      {value: 'Espagne', label: 'Espagne'},
      {value: 'Estonie', label: 'Estonie'},
      {value: 'Finlande', label: 'Finlande'},
      {value: 'France', label: 'France'},
      {value: 'Géorgie', label: 'Géorgie'},
      {value: 'Grèce', label: 'Grèce'},
      {value: 'Hongrie', label: 'Hongrie'},
      {value: 'Irlande', label: 'Irlande'},
      {value: 'Islande', label: 'Islande'},
      {value: 'Italie', label: 'Italie'},
      {value: 'Kazakhstan', label: 'Kazakhstan'},
      {value: 'Kosovo', label: 'Kosovo'},
      {value: 'Lettonie', label: 'Lettonie'},
      {value: 'Liechtenstein', label: 'Liechtenstein'},
      {value: 'Lituanie', label: 'Lituanie'},
      {value: 'Luxembourg', label: 'Luxembourg'},
      {value: 'Macédoine', label: 'Macédoine'},
      {value: 'Malte', label: 'Malte'},
      {value: 'Moldavie', label: 'Moldavie'},
      {value: 'Monaco', label: 'Monaco'},
      {value: 'Moldavie', label: 'Moldavie'},
      {value: 'Monténégro', label: 'Monténégro'},
      {value: 'Norvège', label: 'Norvège'},
      {value: 'Pays-Bas', label: 'Pays-Bas'},
      {value: 'Pologne', label: 'Pologne'},
      {value: 'Portugal', label: 'Portugal'},
      {value: 'République tchèque', label: 'République tchèque'},
      {value: 'Roumanie', label: 'Roumanie'},
      {value: 'Royaume-Uni', label: 'Royaume-Uni'},
      {value: 'Roumanie', label: 'Roumanie'},
      {value: 'Russie', label: 'Russie'},
      {value: 'Saint-Marin', label: 'Saint-Marin'},
      {value: 'Serbie', label: 'Serbie'},
      {value: 'Slovaquie', label: 'Slovaquie'},
      {value: 'Slovénie', label: 'Slovénie'},
      {value: 'Suède', label: 'Suède'},
      {value: 'Suisse', label: 'Suisse'},
      {value: 'Turquie', label: 'Turquie'},
      {value: 'Ukraine', label: 'Ukraine'},
      {value: 'Vatican', label: 'Vatican'},
    ];
  }

  getInvitedPeopleFieldGroup(invitedPeopleKey: string): Array<any> {
    const config = [];
    if (invitedPeopleKey === '1') {
      config.push({
        template: '' +
          '<p>Merci de nous indiquer l\'identité de votre/vos invité(s) / Please fill in the information about your guests</p>'
      });
    }
    config.push({
      template: '<h5 class="mt-5">Invité ' + invitedPeopleKey + ' / Guest ' + invitedPeopleKey +'</h5>'
    });
    config.push({
      key: 'firstname',
      type: 'input',
      wrappers: ['form-field-horizontal'],
      templateOptions: {
        label: 'Prénom / First Name',
        required: true,
      },
    });
    config.push({
      key: 'lastname',
      type: 'input',
      wrappers: ['form-field-horizontal'],
      templateOptions: {
        label: 'Nom / Last Name',
        required: true,
      },
    });
    config.push({
      key: 'emailaddress',
      type: 'input',
      wrappers: ['form-field-horizontal'],
      templateOptions: {
        label: 'Email',
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/,
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `e-mail non valide`,
        },
      },
    });

    return config;
  }

  setStaticFields(): void {

    this.fields = [

      {
        template: '<h5>Nombre de personnes total / Total number of people *</h5>',
      },

      {
        key: 'peoplenumber',
        type: 'select',
        // wrappers: ['form-field-horizontal'],
        props: {
          options: this.getOptionsNumberOfPersons(),
          required: true,
        }
      },

      {
        template: '<hr />' +
          '<p>' +
          'Merci de remplir les rubriques suivantes afin que nous puissions envoyer votre/vos carton(s) d’entrée par voie postale. / Kindly fill in the following information so that we can send your entry card(s) by post.' +
          '</p>' +
          '',
      },

      {
        key: 'firstname',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: "Prénom / First Name",
          required: true,
        },
      },

      {
        key: 'lastname',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Nom / Last Name',
          required: true,
        },
      },

      {
        key: 'function',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Fonction / Job Title',
        },
      },

      {
        key: 'company',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Société / Company name',
        },
      },

      {
        key: 'addressline1',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Adresse / Address',
          required: true,
        },
      },

      {
        key: 'addressline2',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: '-',
        },
      },

      {
        key: 'zip',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'NPA / Postal Code',
          required: true,
        },
      },

      {
        key: 'city',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Ville / City',
          required: true,
        },
      },

      // {
      //   key: 'country',
      //   type: 'ng-select',
      //   wrappers: ['form-field-horizontal'],
      //   templateOptions: {
      //     label: 'Pays',
      //     options: this.listOfCountries,
      //     required: true,
      //     placeholder: 'Veuillez sélectionner'
      //   },
      // },

            {
        key: 'country',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Pays / Country',
          required: true,
        },
      },

      {
        key: 'phonenumber',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Téléphone / Phone number',
          required: true,
          pattern: /^[0-9+\- ]{1,20}$/,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) => `Seuls les caractères numériques sont acceptés / Only numeric values are accepted`,
          },
        },
      },
      {
        key: 'emailaddress',
        type: 'input',
        wrappers: ['form-field-horizontal'],
        props: {
          label: 'Email',
          required: true,
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) => `e-mail non valide / Not valid e-mail`,
          },
        },
      },

      {
        template: '<hr >'
      },

      {
        key: 'invitedpeople1',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('1')
      },
      {
        key: 'invitedpeople2',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('2')
      },
      {
        key: 'invitedpeople3',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('3')
      },
      {
        key: 'invitedpeople4',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('4')
      },
      {
        key: 'invitedpeople5',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('5')
      },
      {
        key: 'invitedpeople6',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('6')
      },
      {
        key: 'invitedpeople7',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('7')
      },
      {
        key: 'invitedpeople8',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('8')
      },
      {
        key: 'invitedpeople9',
        expressions: {
          hide: this.hideField
        },
        fieldGroup: this.getInvitedPeopleFieldGroup('9')
      },


    ];
  }

}
