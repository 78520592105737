import {Component, Input, ViewChild} from '@angular/core';
import {NgbAlert, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';
import {DateService} from '../../../shared/date.service';

@Component({
  selector: 'app-recap-registration',
  templateUrl: './recap-registration.component.html',
  styleUrls: ['./recap-registration.component.scss']
})
export class RecapRegistrationComponent {

  staticAlertClosed = false;

  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  @Input() registrationmodel: any = {};
  @Input() servererror: boolean = false;

  constructor(
    private dateService: DateService
  ) {
  }

  closeAlert(): void {
    this.staticAlertClosed = true;
    this.servererror = false;
  }

  formatDate(date: NgbDateStruct, format: string = 'DD/MM/YYYY'): string {
    if (date) {
      return moment(date.day + '/' + date.month  + '/' + date.year, 'DD/MM/YYYY')
        .format(format);
    } // if

    return '';
  }

  formatDate2(dateString: string, inputFormat: string = 'DD/MM/YYYY', outputFormat: string = 'DD/MM/YYYY'): string {
    const dateMoment = this.dateService.convertDateStringToMoment(dateString, inputFormat);
    return dateMoment.format(outputFormat);
  }

  formatTime(time: NgbTimeStruct): string {
    if (time) {
      return String.prototype.padStart(2, time.hour.toString()) + ':' + String.prototype.padStart(2, time.minute.toString());
    } // if
    return '';
  }


}
