import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  formatDate(date: Date, inputformat: string = 'DD.MM.YYYY', outformat: string = 'DD.MM.YYYY'): string {

    // Convert string date to moment date
    const convertedDate = moment(date, inputformat);
    if (!convertedDate.isValid()) {
      return '[invalid moment date]';
    } // if
    return convertedDate.format(outformat);
  } // formatDate

  // Convert date format from local to utc moment format
  formatDateLocaleToUtcString(date: Date): string {

    // !!!2020-09-04T13:55:54+00:00
    // https://www.iso.org/iso-8601-date-and-time-format.html

    // See https://www.utctime.net/z-time-now

    const utcDate = moment.utc(date.toUTCString());
    // gives: 2020-09-05T15:00:08Z [UTC format, not iso 8601 format]
    // (Zulu Time (Coordinated Universal Time). Time zone offset of Z is UTC)
    return utcDate.format();
  }



  formatDateInUtc(date: Date,
                  inputformat: string = 'DD.MM.YYYY',
                  outformat: string = 'DD.MM.YYYY'): string {
    const convertedDate = moment.parseZone(date, inputformat);
    if (!convertedDate.isValid()) {
      return '[invalid moment date]';
    } // if


    return convertedDate.format(outformat);
  } // formatDateInUtc


  convertFromTypescriptDateToNgbDateStruct(date: Date|string, inputformat: string|any = 'YYYY-MM-DD'): NgbDateStruct {
    const convertedDate = moment(date, inputformat);
    return {
      day: convertedDate.date(),
      month: convertedDate.month() + 1,
      year: convertedDate.year()
    };
  }

  convertDateStringToDate(date: string, inputformat: string|any = 'YYYY-MM-DD'): Date {
    const convertedDate = moment(date, inputformat);
    // console.log('f() convertDateStringToDateSOString');
    // console.log(convertedDate);
    return convertedDate.toDate();
  }

  convertDateStringToMoment(date: string, inputformat: string|any = 'YYYY-MM-DD'): Moment {
    return moment(date, inputformat);
  }

  convertDateStringToNgbDateStruct(date: string, inputformat: string|any = 'YYYY-MM-DD'): NgbDateStruct {
    const momentDate = moment(date, inputformat);
    // console.log(momentDate);
    return {
      day: momentDate.date(),
      month: momentDate.month() + 1,
      year: momentDate.year()
    };
  }

  convertDateStringToUnix(date: string, inputformat: string|any = 'YYYY-MM-DD'): number {
    return moment(date, inputformat).unix();
  }

  convertDateStringToDateISOString(date: string, inputformat: string|any = 'YYYY-MM-DD'): string {
    const convertedDate = moment(date, inputformat);
    // console.log('f() convertDateStringToDateISOString');
    // console.log(convertedDate);
    return convertedDate.toISOString();
  }

  convertDateStringToDateUTCString(date: string, inputformat: string|any = 'YYYY-MM-DD'): string {
    const convertedDate = moment(date, inputformat);
    // console.log('f() convertDateStringToDateUTCString');
    // console.log(convertedDate);
    return convertedDate.utc().toISOString();
  }

  convertArrayOfDateStringsToArrayOfDateISOString(dates: string[], inputformat: string|any = 'YYYY-MM-DD'): string[] {
    const convertedDates = [];
    for (const date of dates) {
      convertedDates.push(this.convertDateStringToDateISOString(date, inputformat));
    } // for
    return convertedDates;
  }

  convertArrayOfDateStringsToArrayOfDateUTCString(dates: string[], inputformat: string|any = 'YYYY-MM-DD'): string[] {
    const convertedDates = [];
    for (const date of dates) {
      convertedDates.push(this.convertDateStringToDateUTCString(date, inputformat));
    } // for
    return convertedDates;
  }

  convertArrayOfDateStringsToArrayOfMoment(dates: string[], inputformat: string|any = 'YYYY-MM-DD'): Moment[] {
    const convertedDates = [];
    for (const date of dates) {
      convertedDates.push(this.convertDateStringToMoment(date, inputformat));
    } // for
    return convertedDates;
  }

  convertArrayOfDateStringsToArrayOfUnix(dates: string[], inputformat: string|any = 'YYYY-MM-DD'): number[] {
    const convertedDates = [];
    for (const date of dates) {
      convertedDates.push(this.convertDateStringToUnix(date, inputformat));
    } // for
    return convertedDates;
  }

  convertArrayOfDateStringsToArrayOfDates(dates: string[], inputformat: string = 'YYYY-MM-DD'): Date[] {
    const convertedDates = [];
    for (const date of dates) {
      convertedDates.push(this.convertDateStringToDate(date, inputformat));
    } // for
    return convertedDates;
  }

  convertMomentDateToNgbDateStruct(momentDate: Moment): NgbDateStruct {
    return {
      day: momentDate.date(),
      month: momentDate.month() + 1,
      year: momentDate.year()
    };
  }

  convertNgbDateStructToMomentLocalTimezone(ngbDate: NgbDateStruct): Moment {
    // Set the time at 00:00:00 UTC

    const momentDate = moment()
      .set('year', ngbDate.year)
      .set('month', ngbDate.month - 1)
      .set('date', ngbDate.day)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
    ;

    // console.log(momentDate.toISOString());
    return momentDate;
  }

  convertArrayOfNgbDateStructToArrayOfMomentLocalTimezone(ngbDates: NgbDateStruct[]): Moment[] {
    const convertedDates = [];
    for (const ngbDate of ngbDates) {
      convertedDates.push(this.convertNgbDateStructToMomentLocalTimezone(ngbDate));
    } // for
    return convertedDates;
  }

  convertMomentLocalTimezoneToISOString(momentItem: Moment): string {
    return momentItem.toISOString();
  }

  convertArrayOfMomentLocalTimezoneToArrayOfISOString(moments: Moment[]): string[] {
    const convertedDates = [];
    for (const momentItem of moments) {
      convertedDates.push( this.convertMomentLocalTimezoneToISOString(momentItem) );
    } // for
    return convertedDates;
  }

}
