

<ng-template #confirmCancellationTemple>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmer</h4>
    <button type="button" class="btn-close pull-right" aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="text-left">Voulez-vous annuler cette inscription?</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Ne pas annuler l'inscription</button>
    <button type="button" class="btn btn-danger" (click)="cancelRegistration()">Annuler l'inscription</button>
  </div>
</ng-template>

<div class="row justify-content-center">
  <div class="btn-group" ngbDropdown container="body" #myDrop="ngbDropdown">
    <div style="width: 40px; height: 40px;" class="row text-center mouse-pointer align-items-center" ngbDropdownToggle>
      <div class="col">
      <i class="fas fa-ellipsis-v fa-lg mouse-pointer text-secondary" ></i>
      </div>
    </div>
    <div  class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
      <button class="dropdown-item" type="button" (click)="openModal(confirmCancellationTemple)" ngbDropdownItem>Annuler l'inscription</button>
    </div>
  </div>
</div>
