import {ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';

import {MatSort} from '@angular/material/sort';

import {ToolGridDirective} from '../tool-grid.directive';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

export interface IDisplayColumn {
  columnName: string;
  label?: string;
  template?: TemplateRef<any>;
  isKeyColumn?: boolean;
}

export interface IKeyTemplate {
  key: string;
  template: TemplateRef<any>;
}

@Component({
  selector: 'app-crud-generic-list',
  templateUrl: './crud-generic-list.component.html',
  styleUrls: ['./crud-generic-list.component.scss']
})
export class CrudGenericListComponent implements OnInit {
  allDisplayedColumns: IDisplayColumn[] = [];
  columnNames: string[] = [];

  @Input() observabledata: Observable<any>;
  @Input() postTreatmentCallback: (data: any) => any;
  @Input() listEditPathCallback: (idvalue: string) => any;
  @Input() displayedColumns: IDisplayColumn[] = [];
  @Input() keyColumns: any = []; // Columns with link
  @Input() idColumnName: string;
  @Input() specificColumnsTemplates: IKeyTemplate[] = [];
  @Input() entityroot: string = "";
  @Input() deleteEntityHandler: (idvalue) => Observable<any>;
  @Input() removePostTreatmentHandler: (idvalue, entityroot) => any;
  @Input() pageSizeOptions: any[] = [20, 100, 500];

  datalistsource: MatTableDataSource<any>; // MatTableDataSource
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ContentChild(ToolGridDirective, { read: TemplateRef }) toolGridTemplate: TemplateRef<any>;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datalistsource.filter = filterValue.trim().toLowerCase();
  }

  preInit() {
    for (const item of this.displayedColumns) {
      this.allDisplayedColumns.push(item);
      this.columnNames.push(item.columnName);
    } // for
    this.columnNames.push('tools-grid');

    // Reassign a template if specific config
    if (this.specificColumnsTemplates) {
      for (const item of this.specificColumnsTemplates) {
        for (let i = 0; i < this.allDisplayedColumns.length; i++) {
          if (this.allDisplayedColumns[i].columnName === item.key) {
            // Assign the template
            this.allDisplayedColumns[i].template = item.template;
          } // if
        } // for
      } // for
    } // if

  } // preInit

  initSpecificConfigs() {
  } // initSpecificConfigs

  buildList() {

    this.observabledata.subscribe( (result) => {

      let postTreatedData = result;
      // Observable post treatment
      if (this.postTreatmentCallback) {
        postTreatedData = this.postTreatmentCallback(result);
      } // if

      this.datalistsource = new MatTableDataSource(postTreatedData);
      this.datalistsource.sort = this.sort;
      this.datalistsource.paginator = this.paginator;

    });
  } // buildList

  ngOnInit(): void {
    this.preInit();
    this.initSpecificConfigs();
    this.buildList();
  } // ngOnInit

  getIdColumnValue(element: any) {
    if (element.hasOwnProperty(this.idColumnName)) {
      return element[this.idColumnName];
    } // if
    return '';
  } // getIdColumnValue

  getEditPath(idColumnValue: any) {
    let url = '/' + idColumnValue;
    if (this.listEditPathCallback) {
      url = this.listEditPathCallback(idColumnValue);
    } // if
    return [url];
  }
}
