import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './views/main/main.component';
import { GphgPageComponent } from './views/gphg-page/gphg-page.component';
import { RegistrationFormComponent } from './views/registration-form/registration-form.component';
import {AbstractControl, ReactiveFormsModule, ValidationErrors} from '@angular/forms';
import {FormlyFieldConfig, FormlyModule} from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import {FormlyHorizontalWrapper} from './shared/library/formly-horizontal-wrapper';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Params} from './shared/params';
import { RegistrationFormSucessComponent } from './views/registration-form-sucess/registration-form-sucess.component';
import { MainAdminComponent } from './views/admin/main-admin/main-admin.component';
import { RegistrationAdminComponent } from './views/admin/registration-admin/registration-admin.component';
import { DashboardAdminComponent } from './views/admin/dashboard-admin/dashboard-admin.component';
import {CrudGenericListComponent} from './shared/library/crud-library/crud-generic-list/crud-generic-list.component';
import {SimpleEllipsisComponent} from './shared/library/crud-library/simple-ellipsis/simple-ellipsis.component';
import {ToolGridDirective} from './shared/library/crud-library/tool-grid.directive';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyFieldNgSelect} from './shared/ng-select-type.components';
import { LoginAdminComponent } from './views/admin/login-admin/login-admin.component';
import {HttpTokenInterceptor} from './security/http-token.interceptor';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { TagEllipsisComponent } from './shared/library/crud-library/tag-ellipsis/tag-ellipsis.component';
import { EventRegistrationFormComponent } from '@views/event-registration-form/event-registration-form.component';
import {
  NgbAlertModule,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {FormlyFieldDatePicker} from './shared/date-picker-field-type';
import {NgbDateCustomParserFormatter} from './shared/ngb-date-custom-parser-formatter';
import { RecapRegistrationComponent } from './views/components/recap-registration/recap-registration.component';
import { RegistrationResultComponent } from './views/components/registration-result/registration-result.component';
import {FormlyFieldDatePickerNgx} from './shared/date-picker-ngx-field-type';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {FormlyFieldCustomTemplate} from './shared/custom-template-field-type';
import { AtelierMainComponent } from './views/admin/mediation/atelier/atelier-main/atelier-main.component';
import { AtelierAddComponent } from './views/admin/mediation/atelier/atelier-add/atelier-add.component';
import { EventAddComponent } from './views/admin/mediation/event/event-add/event-add.component';
import { EventMainComponent } from './views/admin/mediation/event/event-main/event-main.component';
import { RegistrationAddComponent } from './views/admin/mediation/registration/registration-add/registration-add.component';
import { RegistrationMainComponent } from './views/admin/mediation/registration/registration-main/registration-main.component';
import { EventEllipsisComponent } from '@views/admin/mediation/event/event-ellipsis/event-ellipsis.component';
import { RegistrationEllipsisComponent } from './views/admin/mediation/registration/registration-ellipsis/registration-ellipsis.component';


const initializerConfigFn = (params: Params) => {
  return () => {
    return params.loadAppConfig();
  };
};

export function NotZeroValidator(control: AbstractControl): ValidationErrors {
  return control.value > 0 ? null : { notzero: false };
}
export function NotZeroValidatorMessage(error: any, field: FormlyFieldConfig): string {
  return `Ce champ doit être au minimum égal à 1 / Should be number more than zero`;
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    GphgPageComponent,
    RegistrationFormComponent,
    FormlyHorizontalWrapper,
    RegistrationFormSucessComponent,
    MainAdminComponent,
    RegistrationAdminComponent,
    DashboardAdminComponent,


    CrudGenericListComponent,
    SimpleEllipsisComponent,
    ToolGridDirective,

    FormlyFieldNgSelect,
    FormlyFieldDatePicker,
    FormlyFieldDatePickerNgx,
    FormlyFieldCustomTemplate,

    LoginAdminComponent,

    TagEllipsisComponent,
     EventRegistrationFormComponent,
     RecapRegistrationComponent,
     RegistrationResultComponent,
     AtelierMainComponent,
     AtelierAddComponent,
     EventAddComponent,
     EventMainComponent,
     RegistrationAddComponent,
     RegistrationMainComponent,
     EventEllipsisComponent,
     RegistrationEllipsisComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    HttpClientModule,

    ReactiveFormsModule,

    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,

    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgbAlertModule,
    NgbDropdownModule,

    NgSelectModule,

    FormlyModule.forRoot({
      extras: { lazyRender: true },
      wrappers: [{ name: 'form-field-horizontal', component: FormlyHorizontalWrapper }],
      validators: [
        { name: 'notzero', validation: NotZeroValidator}
      ],
      validationMessages: [
        { name: 'required', message: 'Ce champ est requis / Mandatory field' },
        { name: 'notzero', message: NotZeroValidatorMessage },
      ],
      types: [
        { name: 'ng-select', component: FormlyFieldNgSelect, wrappers: ['form-field'] },
        { name: 'date-picker', component: FormlyFieldDatePicker, wrappers: ['form-field'] },
        { name: 'date-picker-ngx', component: FormlyFieldDatePickerNgx, wrappers: ['form-field'] },
        { name: 'custom-template', component: FormlyFieldCustomTemplate, wrappers: ['form-field'] }
      ],
    }),

    FormlyBootstrapModule,

    NgbModule,
    NgbDatepickerModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [Params]
    },
    {
      provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
