
<h2>Ateliers</h2>

<button type="button" class="btn btn-light mt-3" (click)="gotoAdd()">
  <i class="fa-regular fa-square-plus fa-lg"></i>
  Ajouter un nouvel atelier
</button>


<app-crud-generic-list [observabledata]="getObservableData()"
                       [displayedColumns]="displayedcolumns"
                       [postTreatmentCallback]="getPostTreatmentCallback()"
                       [idColumnName]="'id'"
                       [entityroot]="entityroot"
>
</app-crud-generic-list>
