import {Component, Input, OnInit} from '@angular/core';
import {BffApi} from '../../../bff-api';
import {JsonState, State} from '../../../shared-interface';
import {SharedDataService} from '../../../shared-data.service';

@Component({
  selector: 'app-tag-ellipsis',
  templateUrl: './tag-ellipsis.component.html',
  styleUrls: ['./tag-ellipsis.component.scss']
})
export class TagEllipsisComponent implements OnInit {

  @Input() idvaluechild: string;

  states: State;
  idstates: {};

  constructor(
    private bffApi: BffApi,
    private sharedDataService: SharedDataService
  ) {
    this.states = {
      new: false,
      inprogress: false,
      done: false
    };

    this.sharedDataService.idstateobservable$.subscribe((data) => {
      this.idstates = data;
    });

  }

  getStates(): void {
    this.bffApi.getRegistrationState(this.idvaluechild).subscribe((jsonstates) => {
      this.states = jsonstates.results;
    });
  }

  ngOnInit(): void {
    this.getStates();
  }

  onShown(): void {
    this.getStates();
  }

  setState(state: string): void {
    this.sharedDataService.setItemRegistrationListStates(this.idvaluechild, state);
    this.bffApi.setRegistrationState(this.idvaluechild, state).subscribe(() => {
    });
  }

}
