import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  idstateobservable$: BehaviorSubject<any>;

  eventPeopleData$: BehaviorSubject<any>;

  gphgyear$: BehaviorSubject<string>;

  constructor(
    private route: ActivatedRoute
  ) {
    this.idstateobservable$ = new BehaviorSubject<any>({});
    this.eventPeopleData$ = new BehaviorSubject<any>({});
    this.gphgyear$ = new BehaviorSubject<string>("");
  }

  setRegistrationListStates(idstates: {}): void {
    this.idstateobservable$.next(idstates);
  }

  getRegistrationListStates(): any {
    return this.idstateobservable$.getValue();
  }

  setItemRegistrationListStates(id: string, state: string): void {
    const idstates = this.getRegistrationListStates();
    idstates[id] = state;
    this.setRegistrationListStates(idstates);
  }

  setEventPeopleData(eventPeopleData: any): void {
    this.eventPeopleData$.next(eventPeopleData);
  }

  setGphgYear(gphgyear: string): void {
    this.gphgyear$.next(gphgyear);
  }

  getGphgYear(): string {
    return this.gphgyear$.getValue();
  }

}
