import { Component } from '@angular/core';
import {IDisplayColumn} from '../../../../../shared/library/crud-library/crud-generic-list/crud-generic-list.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {EventDateService} from '../../../../../services/event-date.service';

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.scss']
})
export class EventAddComponent {
  displayedcolumns: IDisplayColumn[] = [];
  entityroot: string;
  eventid: string;
  eventkey: string;

  date: string;
  activityLabel: string;

  constructor(
    private eventDateService: EventDateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.eventkey = this.activatedRoute.snapshot.params.eventkey;
    this.entityroot = 'admin/' + this.eventkey + '/events';

    this.displayedcolumns = [
      {columnName: 'createddate', label: "Date d'inscription"},
      {columnName: 'userinfo', label: 'Utilisateur'},
      {columnName: 'seats', label: 'Confirmées'},
      {columnName: 'reminder', label: 'Reminder count'}

    ] as IDisplayColumn[];
  }

  getObservableData(): Observable<any> {
    this.eventid = this.activatedRoute.snapshot.paramMap.get('eventid');

    return this.eventDateService.readEvent(this.eventid);
  }

  getPostTreatmentCallback(): any {
    return (results) => {

      this.date = results?.results?.event?.date;
      this.activityLabel = results?.results?.event?.activity_label;

      return results?.results?.registrations;
    };
  }

  back(): void {
    this.router.navigate([this.entityroot]);
  }


}
