



<div class="container text-center" style="max-width:330px; margin-top: 100px">

    <img src="/assets/img/logo_gphg.jpg" width="100" alt="">
    <h1 class="h4 mt-4 mb-3 font-weight-normal">Se connecter</h1>

    <form [formGroup]="form" >
      <formly-form [form]="form" [fields]="fields" [model]="loginmodel"></formly-form>
      <button class="btn btn-lg btn-primary btn-block" type="button" (click)="login()">Se connecter</button>
    </form>

    <h6 class="mt-3 mb-3 text-muted">
      <small>© FONDATION DU GRAND PRIX D'HORLOGERIE DE GENÈVE</small>
    </h6>


</div>

