import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {SafeHtml} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FormDynamicsService {

  private eventOptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private selectedDateEnabled$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private eventTimeOptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private availableSeats$: BehaviorSubject<number|null> = new BehaviorSubject<number|null>(null);

  private isPersonCountHidden$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private seatTemplate$: BehaviorSubject<SafeHtml> = new BehaviorSubject<SafeHtml>('');
  constructor(
  ) {
  }


  setEventOptions(options: any): void {
    this.eventOptions$.next(options);
  }

  getEventOptions$(): Observable<any> {
    return this.eventOptions$;
  }

  setSelectedDateEnabled(value: any): void {
    this.selectedDateEnabled$.next(value);
  }

  getSelectedDateEnabled$(): Observable<any> {
    return this.selectedDateEnabled$;
  }

  getSelectedDateEnabled(): any {
    return this.selectedDateEnabled$.getValue();
  }

  setEventTimeOptions(options: any): void {
    this.eventTimeOptions$.next(options);
  }

  getEventTimeOptions$(): Observable<any> {
    return this.eventTimeOptions$;
  }

  setAvailableSeat(value: number|null): void {
    this.availableSeats$.next(value);
  }

  getAvailableSeats$(): Observable<number|null> {
    return this.availableSeats$;
  }

  setIsPersonCountHidden(value: string): void {
    this.isPersonCountHidden$.next(value);
  }

  getIsPersonCountHidden$(): Observable<string> {
    return this.isPersonCountHidden$;
  }

  setSeatTemplate(value: SafeHtml): void {
    this.seatTemplate$.next(value);
  }

  getSeatTemplate$(): Observable<SafeHtml> {
    return this.seatTemplate$;
  }

}
