import * as XLSX from 'xlsx';

export class TableUtils {
  static exportTableToExcel(tableId: string, name?: string): void {
    const targetTableElm = document.getElementById(tableId);
    const wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts> {
      sheet: 'export'
    });
    XLSX.writeFile(wb, `output.xlsx`);
  }

  static exportArrayToExcel(arr: any[], fileName?: string): void {
    const sheetName = 'export';
    if (!fileName) {
      fileName = 'output.xlsx';
    } // if
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);
  }
}
