import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivityService} from '../../../../../services/activity.service';

@Component({
  selector: 'app-atelier-add',
  templateUrl: './atelier-add.component.html',
  styleUrls: ['./atelier-add.component.scss']
})
export class AtelierAddComponent implements OnInit {

  entityroot: string;

  form: FormGroup;
  fields: FormlyFieldConfig[] = [];
  model: any = {};
  formstep: string = '';
  options: FormlyFormOptions;

  constructor(
    private activatedRoute: ActivatedRoute,
    private activityService: ActivityService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.entityroot = 'admin/atelier';

    this.form = new FormGroup<any>({});

    this.fields = [
      {
        key: 'label',
        type: 'textarea',
        props: {
          label: 'Label',
          placeholder: '',
          required: true,
          rows: 4
        }
      },
      {
        key: 'description',
        type: 'textarea',
        props: {
          label: 'Description',
          placeholder: '',
          rows: 5
        }
      },
      {
        key: 'eventkey',
        type: 'input',
        props: {
          label: 'Evénement',
          placeholder: '',
        }
      },
      {
        key: 'value',
        type: 'input',
        props: {
          label: 'Clé',
          placeholder: '',
          required: true
        }
      },
      {
        key: 'groupid',
        type: 'input',
        props: {
          label: 'Groupe clé',
          placeholder: '',
        }
      },
      {
        key: 'sort',
        type: 'number',
        props: {
          label: 'Tri',
          placeholder: ''
        }
      },
    ];

    // Set the model if it is an edition
    const atelierid = this.activatedRoute.snapshot.paramMap.get('atelierid');
    console.log(atelierid);
    this.activityService.readActivity(atelierid).subscribe({
      next: results => {
        this.model = results?.results;
      }
    });

  }

  submit(): void {
    this.back();
  }

  back(): void {
    this.router.navigate([this.entityroot]);
  }

}
